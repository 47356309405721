import Card from "components/card";
import React, { useState } from "react";
import axios from "../../../../api/axios";
import Swal from "sweetalert2";

const Editpass = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const storedToken = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  let token = "Bearer " + storedToken.replace(/"/g, "");
  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const body = {
      userId: userId,
      currentPassword: password,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    axios
      .post("/api/account/changepassword", body, {
        headers: headers,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Fjalëkalimi juaj u ndryshua me sukses!",
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
          timer: 2500,
        });
        setTimeout(() => {
          window.location.href = "/auth/sign-in";
        }, 2500);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "Diçka shkoi keq! Ju lutem provoni përseri",
          timer: 2500,
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
        });
      });
  };
  return (
    <Card extra={"items-center w-full p-[16px] bg-cover"}>
      <header className="items-center">
        <div className="pb-4 pt-4 text-center text-xl font-bold text-navy-700 dark:text-white">
          Ndrysho fjalëkalimin
        </div>
        <hr />
      </header>
      <div className="mt-[4vh] w-full max-w-full flex-col items-center md:pt-6 lg:w-1/2 lg:pl-0 ">
        <div className="flex flex-wrap">
          <div className="mb-3 w-full md:w-full md:flex-col">
            <label htmlFor="email">Fjalëkalimi aktual</label>
            <input
              label="Fjalëkalimi i tanishëm*"
              placeholder="Min. 8 karaktere"
              id="name"
              type="password"
              className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
              name="email"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="mb-3 w-full md:w-full md:flex-col">
            <label htmlFor="email">Fjalëkalimi i ri</label>

            <input
              label="Fjalëkalimi i ri*"
              placeholder="Min. 8 karaktere"
              id="surname"
              type="password"
              className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
              name="email"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-wrap pb-4">
          <div className="mb-3 w-full md:w-full md:flex-col">
            <label htmlFor="email">Konfirmo fjalëkalimin</label>
            <input
              extra="mb-3"
              label="Konfirmo fjalëkalimin*"
              placeholder="Min. 8 karaktere"
              id="text"
              type="password"
              className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
              name="email"
              value={confirmPassword}
              onChange={(event) => setconfirmPassword(event.target.value)}
            />
          </div>
        </div>
        <button
          onClick={handleSubmit}
          className="linear mb-4 mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Ndrysho
        </button>
      </div>
    </Card>
  );
};

export default Editpass;
