import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import axios from "../../api/axios";

const LOGIN_URL = "/api/account/signin";

export default function SignIn() {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/admin/dashboard";

  const userRef = useRef();
  const pwdRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [emailErrMsg, setEmailErrMsg] = useState("");
  const [pwdErrMsg, setPwdErrMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setEmailErrMsg("");
    setPwdErrMsg("");
    setErrMsg("");
  }, [email, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.trim() === "") {
      setEmailErrMsg("Email adresa duhet të plotësohet");
      return;
    }

    if (pwd.trim() === "") {
      setPwdErrMsg("Fjalëkalimi duhet të plotësohet");
      return;
    }

    try {
      const body = {
        email: email,
        password: pwd,
      };
      const response = await axios.post(LOGIN_URL, JSON.stringify(body), {
        headers: { "Content-Type": "application/json" },
      });
      const accessToken = response?.data?.Token;
      const roles = response?.data?.Roles;
      localStorage.setItem("token", accessToken);

      if (response.status === 200) {
        const storedToken = localStorage.getItem("token");

        let token = "Bearer " + storedToken.replace(/"/g, "");
        const headers = {
          Authorization: token,
          "Content-Type": "application/json",
        };
        const currentLogiInResponse = await axios.get(
          `/api/account/current-user`,
          {
            headers: headers,
          }
        );
        localStorage.setItem("userId", currentLogiInResponse.data?.id);
        localStorage.setItem("name", currentLogiInResponse.data?.name);
      }

      setAuth({ email, pwd, roles, accessToken });
      setEmail("");
      setPwd("");
      navigate(from, { replace: true });
    } catch (err) {
      if (err.response?.status === 400) {
        setErrMsg("Kredencialet e gabuara");
      } else if (err.response?.status === 401) {
        setErrMsg("Nuk jeni të autorizuar për qasje!");
      } else {
        setErrMsg("Gabim teknik");
      }
      errRef.current.focus();
    }
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[16vh] w-full max-w-full flex-col items-center lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 ">
          Kyçu
        </h4>
        <p className="mb-8 ml-1 text-base text-gray-600">
          Ju lutemi plotësoni email adresen dhe fjalëkalimin për tu kyçur!
        </p>
        <div className="mb-6 flex items-center gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        <label htmlFor="email" className="">
          Email
        </label>
        <input
          label="Email*"
          placeholder="Shkruani email adresen"
          id="email"
          type="text"
          ref={userRef}
          autoComplete="off"
          className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm  outline-none `}
          name="email"
          value={email}
          required
          onChange={(event) => {
            setEmail(event.target.value);
            setEmailErrMsg("");
          }}
        />
        {emailErrMsg && (
          <p className="mb-2 text-sm text-red-500">{emailErrMsg}</p>
        )}
        <div className="pt-4">
          <label htmlFor="password" className="mt-2">
            Fjalëkalimi
          </label>
          <input
            placeholder="Min. 8 karaktere"
            id="password"
            type="password"
            className={`flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
            value={pwd}
            required
            ref={pwdRef}
            onChange={(event) => {
              setPwd(event.target.value);
              setPwdErrMsg("");
            }}
          />
          {pwdErrMsg && (
            <p className="mb-2 text-sm text-red-500">{pwdErrMsg}</p>
          )}
        </div>

        <div className="mb-4 flex items-center justify-between px-2 pt-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="persist"
              onChange={togglePersist}
              checked={persist}
            />
            <p className="ml-2 text-sm font-medium text-gray-600">
              Më mbaj të lidhur
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href="/auth/forgot-pass"
          >
            Keni harruar fjalëkalimin?
          </a>
        </div>
        {errMsg && <p className="mb-2 text-sm text-red-500">{errMsg}</p>}
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium hover:bg-brand-600 text-white"
          onClick={handleSubmit}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSubmit(event);
            }
          }}
        >
          Kyçu
        </button>
      </div>
    </div>
  );
}