import React, { useState } from "react";

const priceMap = {
  Komunikacion: null,
  "Produkte pa afat": null,
  "Barna pa banderol": null,
};

function PriceCalculator() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [price, setPrice] = useState("");
  const [bonus, setBonus] = useState("");
  const [bonusAmount, setBonusAmount] = useState(0);

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    setPrice(priceMap[category]);
  };

  const handlePriceChange = (event) => {
    const priceValue = event.target.value;
    setPrice(priceValue);
  };

  const handleBonusChange = (event) => {
    const bonusValue = event.target.value;
    setBonus(bonusValue);
  };

  const handleUpdatePrice = () => {
    const enteredPrice = parseFloat(price);
    const bonusPercentage = parseFloat(bonus);
    const bonusAmount = enteredPrice * (bonusPercentage / 100);
    const priceWithBonus = enteredPrice;
    priceMap[selectedCategory] = priceWithBonus.toFixed(2);
    setPrice(priceWithBonus.toFixed(2));
    setBonusAmount(bonusAmount);
  };

  return (
    <div className="pt-35 card rounded-lg bg-white p-20 shadow-md">
      <h2 className="text-xl font-bold">
        Kalkulo shpërblimin për kategorinë e zgjedhur
      </h2>
      <div className="mt-4 flex flex-col">
        <label htmlFor="category" className="mr-2">
          Zgjedh një kategori:
        </label>
        <select
          id="category"
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="rounded-md border px-2 py-1"
        >
          <option value="">--Zgjedh një kategori--</option>
          <option value="Komunikacion">Komunikacion</option>
          <option value="Produkte pa afat">Produkte pa afat</option>
          <option value="Barna pa banderol">Barna pa banderol</option>
        </select>
      </div>
      {selectedCategory && (
        <div className="mt-4 flex flex-col">
          <label htmlFor="price" className="mr-2">
            Gjoba e vendosur në € :
          </label>
          <input
            id="price"
            type="number"
            value={price}
            onChange={handlePriceChange}
            className="rounded-md border px-2 py-1 after:absolute after:inset-y-0 after:right-0 after:block after:pr-3 after:content-['$']"
          />
          <label htmlFor="bonus" className="ml-2 mr-2">
            shpërblimi që përfiton raportuesi në % :
            <span className="ml-2 text-sm text-gray-500">
              (Përqindja nga gjoba e shqiptuar në bazë të kategorisë)
            </span>
          </label>

          <input
            id="bonus"
            type="number"
            value={bonus}
            onChange={handleBonusChange}
            className="rounded-md border px-2 py-1"
          />
          <button
            className="btn mt-4 bg-brand-500 px-4 py-1 text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={handleUpdatePrice}
          >
            {priceMap[selectedCategory] ? "Përditso çmim" : "Shto çmim"}
          </button>
        </div>
      )}
      {priceMap[selectedCategory] && (
        <div className="mt-4">
          <p>
            shpërblimi për <strong>{selectedCategory}</strong> është{" "}
            <strong>${bonusAmount.toFixed(2)}</strong>.
          </p>
          <p className="text-sm text-gray-600">
            Çmimet mund të ndryshojnë pa paralajmërim.
          </p>
        </div>
      )}
    </div>
  );
}

export default PriceCalculator;
