export const columnsDataComplex = [
  {
    Header: "Emri i raportuesit",
    accessor: "name",
  },
  {
    Header: "Data",
    accessor: "date",
  },
  {
    Header: "Statusi",
    accessor: "aprovuar",
  },
  {
    Header: "Pagesa",
    accessor: "paysum",
  },
  {
    Header: "Shiko detajet",
    accessor: "detajet",
  },
 
];
export const columnsDataCheck = [
  {
    Header: "Emri i përdoruesit",
    accessor: "userFullName",
  },
  {
    Header: "Shuma e transferit",
    accessor: "amount",
  },
  {
  Header:"Numri i llogarisë",
  accessor:"bankingNumber"
  },
  {
    Header: "Data e transferit",
    accessor: "transactionSentDate"
  },
  {
    Header: "Shiko detajet",
    accessor: "id"
  }

];
