import Card from "components/card";
import axios from "../../../../api/axios";
import { useMemo, useState } from "react";
import VerificationDetails from "./VerificationDetails";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { TailSpin } from "react-loader-spinner";

const ComplexTable = (props) => {
  const { columnsData, tableData } = props;
  const data = useMemo(() => tableData, [tableData]);
  const [verificationModel, setverificationModel] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = useState(true);

  
  const fetchReportDetails = async (reportId) => {
    const storedToken = localStorage.getItem("token");
    const token = "Bearer " + storedToken.replace(/"/g, "");
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(
        `/api/verification/userinfo?userId=${reportId}`,
        { headers }
      );
      setverificationModel(response.data);
    } catch (error) {}
  };

  const customLocaleText = {
    noRowsLabel: "Nuk ka verifikim në proces",
    footerRowSelected: (count) =>
      `${count === 1 ? "1 rresht" : `${count} rreshta`} i selektuar`,
  };

  const columns = [
    {
      field: "name",
      headerName: <b>Emri dhe mbiemri</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "date",
      headerName: <b>Data e dërgimit</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
      valueFormatter: ({ value }) => {
        return new Date(value).toLocaleString("en-US", {
          dateStyle: "short",
          timeStyle: "short",
        });
      },
    },
    {
      field: "email",
      headerName: <b>Email adresa</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "detajet",
      headerName: <b>Shiko detajet</b>,
      id: "mymodal",
      flex: 0.7,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <label
          id="mymodal"
          htmlFor="my-modal-5"
          className="rounded-xl bg-brand-500 px-[15px] py-[8px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={() => fetchReportDetails(params.row.id)}
        >
          Detajet
        </label>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token");

      let token = "Bearer " + storedToken.replace(/"/g, "");
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get("/api/verification/users", {
          headers,
        });
        setRows(response.data.reverse());
        setLoading(false);
      } catch (error) {}
    };
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="mt-11 flex items-center justify-center pt-11">
          <TailSpin bg-brand-500 height={70} width={70} />
        </div>
      ) : (
        <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
          <header className="relative flex items-center justify-between">
            <div className="pb-2 text-xl font-bold text-black text-navy-700 dark:text-white">
              Lista e verifikimit
            </div>
            {/* modali per verifikim ne detaje */}
            <VerificationDetails verificationModel={verificationModel} />;
          </header>
          <Box>
            <DataGrid
              rows={rows}
              columns={columns}
              className="text-black dark:text-white"
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableSelectionOnClick={true}
              disableColumnMenu
              localeText={customLocaleText}
            />
          </Box>
        </Card>
      )}
    </>
  );
};

export default ComplexTable;
