export const columnsDataCheck = [
  {
    Header: "Emri dhe mbiemri",
    accessor: "fullName",
  },
  {
    Header: "Nr.Telefonit",
    accessor: "phone",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Pozita",
    accessor: "role",
  },
  {
    Header: "Institucioni",
    accessor: "institution",
  },
];


