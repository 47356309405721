import React from "react";
import { useState } from "react";

function ReviewdReportDetails({ selectedReport }) {
  const date = new Date(selectedReport?.date);
  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  const combinedMedia = [
    ...(selectedReport?.photos || []),
    ...(selectedReport?.videos || []),
  ];
  const [selectedMedia, setSelectedMedia] = useState(null);

  const isVideo = (media) =>
    media.endsWith(".mp4") || media.endsWith(".avi") || media.endsWith(".mov");

  return (
    <>
      <input type="checkbox" id="my-modal-5" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box bottom-0 w-11/12 max-w-5xl bg-white p-0 pe-6 ps-4">
          <div className="sticky top-0 z-10 bg-white pt-4">
            <h3 className="abdo sticky top-0 flex justify-center pb-2 text-lg font-bold text-brand-500">
              Detajet e raportuesit!
            </h3>
            <label
              htmlFor="my-modal-5"
              className="btn-sm btn-circle btn absolute right-2 top-3 bg-white text-brand-700 hover:text-white"
            >
              ✕
            </label>
            <hr />
          </div>
          <div className="mb-4 flex items-center pt-4">
            <p className="text-black-500 text-lg font-bold dark:text-white">
              Emri i raportuesit:
            </p>
            <p className="ml-2 text-lg "> {selectedReport?.senderName}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">Email adresa:</p>
            <p className="ml-2 text-lg ">{selectedReport?.email}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">Kategoria:</p>
            <p className="ml-2 text-lg ">{selectedReport?.category}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">Data:</p>
            <p className="ml-2 text-lg ">{formattedDate}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">Përshkrimi:</p>
            <p className="ml-2 text-lg ">{selectedReport?.context}</p>
          </div>
          <div className="carousel z-40 w-full pb-5">
            {combinedMedia.length > 0 && (
              <div className="carousel w-full">
                {combinedMedia.map((media, index) => (
                  <div
                    key={index}
                    id={"slide" + index}
                    className="carousel-item relative flex w-full justify-center"
                  >
                    {isVideo(media) ? (
                      <video
                        src={media}
                        className="h-80 cursor-pointer"
                        onClick={() => setSelectedMedia(media)}
                        controls
                      />
                    ) : (
                      <img
                        src={media}
                        className="h-80 cursor-pointer"
                        alt="..."
                        onClick={() => setSelectedMedia(media)}
                      />
                    )}
                    {combinedMedia.length > 1 && (
                      <div className="absolute left-0 right-0 top-1/2 flex translate-y-1/2 transform justify-between">
                        <a
                          href={
                            "#slide" +
                            (index === 0 ? combinedMedia.length - 1 : index - 1)
                          }
                          className="btn-circle btn bg-white text-brand-700 hover:text-white"
                        >
                          ❮
                        </a>
                        <a
                          href={"#slide" + ((index + 1) % combinedMedia.length)}
                          className="btn-circle btn bg-white text-brand-700 hover:text-white"
                        >
                          ❯
                        </a>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {selectedMedia && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-90">
                <div className="rounded-lg bg-white p-5">
                  {isVideo(selectedMedia) ? (
                    <video
                      src={selectedMedia}
                      alt="..."
                      className="h-[40rem] w-[40rem]"
                      controls
                    />
                  ) : (
                    <img
                      src={selectedMedia}
                      alt="..."
                      className="h-[40rem] w-[40rem]"
                    />
                  )}
                  <button
                    className="btn mt-4 border-0 bg-brand-500 text-white hover:bg-brand-700"
                    onClick={() => setSelectedMedia(null)}
                  >
                    Kthehu
                  </button>
                </div>
              </div>
            )}
          </div>
          <hr />

          <h3 className="flex justify-center pt-4 text-lg font-bold text-brand-500">
            Të dhënat dhe përshkrimi i zyrtarit!
          </h3>
          <div className="mb-4 flex items-center pt-4">
            <p className="text-black-500 text-lg font-bold dark:text-white">
              Emri dhe mbiemri i zyrtarit:
            </p>
            <p className="ml-2 text-lg ">{selectedReport?.adminName}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-black-500 text-lg font-bold dark:text-white">
              Institucioni:
            </p>
            <p className="ml-2 text-lg ">{selectedReport?.institution}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">Aprovuar:</p>
            <p className="ml-2 text-lg">{selectedReport?.isApproved}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">Përshkrimi:</p>
            <p className="ml-2 text-lg">{selectedReport?.description}</p>
          </div>
          <footer className="modal-action sticky bottom-0 bg-white pb-2 pt-2">
            <label
              htmlFor="my-modal-5"
              className="btn border-0 bg-brand-500 text-white hover:bg-brand-700"
            >
              Mbyll
            </label>
          </footer>
        </div>
      </div>
    </>
  );
}

export default ReviewdReportDetails;
