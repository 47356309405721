import logo from "assets/img/auth/Success.svg";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios";

export default function VerificationEmailFlutter() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const userId = queryParams.get("userId");
  
  useEffect(() => {
    const sentEmailRequest = async () => {
      await axios.post(
        `/api/account/confirmemail?userId=${userId}&token=${token}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    };
    sentEmailRequest();
  }, []);
  return (
    <div className="mb-16 mt-4 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className=" w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="mb-6 flex items-center gap-3 pt-4">
          <img className="sm:p-24 md:p-20 lg:p-16" src={logo} alt="Logo" />
        </div>
        <h6 className="mb-2.5 pt-4 text-center text-2xl font-bold text-navy-700 dark:text-white">
          Llogaria juaj është konfirmuar!
        </h6>
      </div>
    </div>
  );
}
