import React from "react";
import axios from "../../../../api/axios";
import Swal from "sweetalert2";

function PaymentDetails(props) {
  const { paymentModel } = props;
  const date = new Date(paymentModel?.sendDate);
  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  const handleReviewTransaction = async (approved) => {
    const storedToken = localStorage.getItem("token");
    const token = "Bearer " + storedToken.replace(/"/g, "");
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    const body = {
      TransactionId: paymentModel.transactionId,
      AdminId: "dec8113a-d62e-42b2-a891-2acbf1b41e41",
      Amount: paymentModel.amount,
      Approved: approved,
    };

    const response = await axios.post(
      `/api/transaction/review`,
      JSON.stringify(body),
      { headers }
    );
    if (response.status === 200) {
      if (approved) {
        Swal.fire({
          icon: "success",
          text: "Transaksioni është aprovuar me sukses!",
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
          timer: 2500,
        });
        setTimeout(() => {
          window.location.href = "/admin/payment";
        }, 2500);
      }else{
        Swal.fire({
        icon: "success",
        text: "Transaksioni është refuzuar me sukses!",
        showConfirmButton: true,
        confirmButtonColor: "#18817d",
        confirmButtonText: "Mbyll",
        timer: 2500,
      });
      setTimeout(() => {
        window.location.href = "/admin/payment";
      }, 2500);
      }
      
    } else if (response.status === 400) {
      Swal.fire({
        icon: "error",
        text: "Gabim në sistem!",
        showConfirmButton: true,
        confirmButtonColor: "#18817d",
        confirmButtonText: "Mbyll",
        timer: 2500,
      });
      setTimeout(() => {
        window.location.href = "/admin/payment";
      }, 2500);
    }
  };

  return (
    <>
      <input type="checkbox" id="my-modal-5" className="modal-toggle" />
      <div className="modal">
         <div className="modal-box p-0 bottom-0 ps-4 pe-6 w-11/12 max-w-5xl bg-white">
          <div className="sticky top-0 bg-white pt-4">
            <h3 className="abdo sticky pb-2 top-0 flex justify-center text-lg font-bold text-brand-500">
            Detajet e përdoruesit!
          </h3>
          <label
              htmlFor="my-modal-5"
              className="btn-sm btn-circle btn absolute right-0 top-3 bg-white text-brand-700 hover:text-white"
            >
              ✕
            </label>
          <hr />
          </div>
          <div className="mb-4 flex items-center pt-4">
            <p className="text-black-500 text-lg font-bold ">
              Emri i raportuesit:
            </p>
            <p className="ml-2 text-lg">{paymentModel.userName}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold ">
              Numri i telefonit:
            </p>
            <p className="ml-2 text-lg ">
              {paymentModel.mobileNumber}
            </p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold ">Emri i bankes:</p>
            <p className="ml-2 text-lg ">{paymentModel.bankName}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold ">
              Numri i llogarisë:
            </p>
            <p className="ml-2 text-lg text-brand-black">
              {paymentModel.accountNumber}
            </p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold ">
              Shuma e tërheqjes:
            </p>
            <p className="ml-2 text-lg">
              {paymentModel.amount + " €"}
            </p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold ">Data:</p>
            <p className="ml-2 text-lg">{formattedDate}</p>
          </div>
          <footer className="modal-action pb-3">
            
            <label
              htmlFor="my-modal-5"
              className="btn border-0 bg-white text-brand-700 hover:text-white"
            >
              Kthehu
            </label>
            <label
              htmlFor="my-modal-6"
              className="btn border-0 bg-red-500 text-white hover:bg-red-600"
              onClick={() => {
                handleReviewTransaction(false);
              }}
            >
              Refuzo
            </label>
            <label
              className="btn border-0 bg-brand-500 text-white hover:bg-brand-700"
              onClick={() => {
                handleReviewTransaction(true);
              }}
            >
              Aprovo
            </label>
          </footer>
        </div>
      </div>
    </>
  );
}

export default PaymentDetails;
