import TotalSpent from "views/admin/dashboard/components/TotalSpent";
import {
  MdAdminPanelSettings,
  MdEuro,
  MdNoteAdd,
  MdPeople,
} from "react-icons/md";
import { columnsDataCheck } from "views/admin/raport/variables/columnsData";
import Widget from "components/widget/Widget";
import axios from "../../../api/axios";
import React, { useState, useEffect } from "react";
import CheckTableDashboard from "../raport/components/checkTableDashboard";
import { TailSpin } from 'react-loader-spinner'

const headerObj = {
  totalReports: 0,
  administrators: 0,
  amount: 0,
  users: 0,
};

const Dashboard = () => {
  const [headerData, setHeaderData] = useState(headerObj);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token");

      let token = "Bearer " + storedToken.replace(/"/g, "");
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get(
          "/api/reports/getallreports",
          { headers }
        );
        setData(response.data);
        setLoading(false); 
      } catch (error) {
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token");

      let token = "Bearer " + storedToken.replace(/"/g, "");
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get(
          "/api/dashboard/getheader",
          { headers }
        );
        setHeaderData(response.data);
        setLoading(false); 
      } catch (error) {
      }
    };
    fetchData();
  }, []);

  return (
    <>
    {loading ? (
      <div className="flex items-center justify-center pt-11 mt-11">
        <TailSpin bg-brand-500 height={70} width={70} />
      </div>
    ) : (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 3xl:grid-cols-4">
        <Widget
          icon={<MdNoteAdd className="h-7 w-7" />}
          title={"Raportimet e dërguara"}
          subtitle={headerData["totalReports"]}
        />
        <Widget
          icon={<MdEuro className="h-6 w-6" />}
          title={"Totali i pagesave"}
          subtitle={headerData["amount"]}
        />
        <Widget
          icon={<MdAdminPanelSettings className="h-7 w-7" />}
          title={"Administratorët"}
          subtitle={headerData["administrators"]}
        />
        <Widget
          icon={<MdPeople className="h-6 w-6" />}
          title={"Përdoruesit e mRep"}
          subtitle={headerData["users"]}
        />
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-1">
        <TotalSpent />
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
        <div>
          <CheckTableDashboard
            columnsData={columnsDataCheck}
            tableData={Array.isArray(data) ? data.slice(-3) : []}
          />
        </div>
      </div>
    </div>
      )}
      </>
  );
};

export default Dashboard;
