import React, { useState } from "react";
import axios from "../../../../api/axios";
import Swal from "sweetalert2";

const Refuse = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const storedToken = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  let token = "Bearer " + storedToken.replace(/"/g, "");
  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };

  const sendTextValue = (event) => {
    event.stopPropagation();

    if (!selectedOption) {
      setErrorMessage("Ju lutemi zgjedhni njërën nga arsyjet e refuzimit!");
      return;
    }

    const body = {
      adminId: userId,
      reportId: props.id,
      description: selectedOption,
      amount: 0,
      approved: false,
    };

    axios
      .post("/api/reports/refuse", body, {
        headers: headers,
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Raportimi është refuzuar me sukses!",
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
          timer: 2500,
        });
        setTimeout(() => {
          window.location.href = "/admin/report";
        }, 2500);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "Diçka shkoi keq! Ju lutem provoni përseri",
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
          timer: 2500,
        });
      });
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    setErrorMessage("");
  };

  return (
    <>
      <input type="checkbox" id="my-modal-6" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-5xl bg-white">
          <h3 className="flex justify-center text-lg font-bold pb-4">
            Refuzim i raportit
          </h3>
          <hr />
          <select
            className="select select-bordered select-sm w-full bg-white"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option value="" disabled>
              Zgjedhni arsyjen e refuzimit:
            </option>
            <option value="Raporti është refuzuar pasi nuk shihen të dhënat">
              Raporti është refuzuar pasi nuk shihen të dhënat
            </option>
            <option value="Raporti është refuzuar pasi nuk është i saktë">
              Raporti është refuzuar pasi nuk është i saktë
            </option>
          </select>

          {errorMessage && (
            <div className="error-message text-red-500">{errorMessage}</div>
          )}

          <footer className="modal-action">
            <label
              htmlFor="my-modal-6"
              className="btn-sm btn-circle btn absolute right-2 top-2"
            >
              ✕
            </label>
            <label
              htmlFor="my-modal-6"
              className="btn border-0 bg-white text-brand-700 hover:text-white"
            >
              Kthehu
            </label>
            <lable
              htmlFor="my-modal-6"
              className="btn border-0 bg-red-500 text-white hover:bg-red-600"
              onClick={sendTextValue}
            >
              Refuzo
            </lable>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Refuse;
