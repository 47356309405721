export const columnsDataComplex = [
  {
    Header: "Emri dhe mbiemri",
    accessor: "name",
  },
  {
    Header: "Data e dërgimit",
    accessor: "date",
  },
  {
    Header: "Email adresa",
    accessor: "email"
  },
  {
    Header: "Shiko detajet",
    accessor: "detajet"
  },
];
