import React from "react";
import Refuse from "./refuse";
import Approve from "./approve";
import { useState } from "react";

function ReportDetailsModal({ selectedReport }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const date = new Date(selectedReport?.date);
  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  const combinedMedia = [
    ...(selectedReport?.photos || []),
    ...(selectedReport?.videos || []),
  ];
  const [selectedMedia, setSelectedMedia] = useState(null);
  console.log("Report" + JSON.stringify(selectedReport));
  const isVideo = (media) =>
    media.endsWith(".mp4") || media.endsWith(".avi") || media.endsWith(".mov");

  return (
    <>
      <input type="checkbox" id="my-modal-5" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box p-0 bottom-0 ps-4 pe-6 w-11/12 max-w-5xl bg-white">
          <div className="sticky top-0 bg-white pt-4">
            <h3 className="abdo sticky top-0 flex justify-center text-lg font-bold text-brand-500">
              Detajet e raportuesit!
            </h3>
            <div className="pt-2">
            <label
              htmlFor="my-modal-5"
              className="btn-sm btn-circle btn absolute top-3 right-0  bg-white text-brand-700 hover:text-white"
              onClick={() => setSelectedImage(null)}
            >
                ✕
            </label>
            </div>
            <hr />
          </div>
          <div className="mb-4 flex items-center pt-4">
            <p className="text-black-500 text-lg font-bold dark:text-white">
              Emri i raportuesit:
            </p>
            <p className="ml-2 text-lg ">{selectedReport?.name}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">Email adresa:</p>
            <p className="ml-2 text-lg ">{selectedReport?.senderEmail}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">Qyteti:</p>
            <p className="ml-2 text-lg ">{selectedReport?.address}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold ">Kategoria:</p>
            <p className="ml-2 text-lg ">{selectedReport?.category}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">Data:</p>
            <p className="ml-2 text-lg ">{formattedDate}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">Pershkrimi:</p>
            <p className="ml-2 text-lg ">{selectedReport?.textContent}</p>
          </div>
          {combinedMedia.length > 0 && (
            <div className="carousel w-full">
              {combinedMedia.map((media, index) => (
                <div
                  key={index}
                  id={"slide" + index}
                  className="carousel-item relative flex w-full justify-center"
                >
                  {isVideo(media) ? (
                    <video
                      src={media}
                      className="h-80 cursor-pointer"
                      onClick={() => setSelectedMedia(media)}
                      controls
                    />
                  ) : (
                    <img
                      src={media}
                      className="h-80 cursor-pointer"
                      alt="..."
                      onClick={() => setSelectedMedia(media)}
                    />
                  )}
                  {combinedMedia.length > 1 && (
                    <div className="absolute left-0 right-0 top-1/2 flex -translate-y-1/2 transform justify-between">
                      <a
                        href={
                          "#slide" +
                          (index === 0 ? combinedMedia.length - 1 : index - 1)
                        }
                        className="btn-circle btn  bg-white text-brand-700 hover:text-white"
                      >
                        ❮
                      </a>
                      <a
                        href={"#slide" + ((index + 1) % combinedMedia.length)}
                        className="btn-circle btn bg-white text-brand-700 hover:text-white"
                      >
                        ❯
                      </a>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          <footer className="modal-action sticky bottom-0 pt-2 pb-4 bg-white">
            <div className="pb-2 pt-2"> 
              <hr/>
            </div>
            <hr/>
            <label
              htmlFor="my-modal-5"
              className="btn border-0  bg-white text-brand-700 hover:text-white"
            >
              Kthehu
            </label>
            <label
              htmlFor="my-modal-6"
              className="btn border-0 bg-red-500 text-white hover:bg-red-600"
            >
              Refuzo
            </label>
            <label
              htmlFor="my-modal-7"
              className="btn border-0 bg-brand-500 text-white hover:bg-brand-700"
            >
              Aprovo
            </label>
            {/* Refuzimi */}
            <Refuse id={selectedReport?.id} />
            {/* Aprovimi */}
            <Approve id={selectedReport?.id} />
          </footer>
        </div>
      </div>
    </>
  );
}

export default ReportDetailsModal;
