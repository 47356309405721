import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios";

export default function NewPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const userId = queryParams.get("userId");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setPasswordError("Fjalëkalimi nuk përputhet");
    } else if (password.length === 0 || confirmPassword.length === 0) {
      setPasswordError("Ju lutem shkruani fjalëkalimin");
    } else {
      const body = {
        Email: userId,
        Token: token,
        NewPassword: password,
        ConfirmPassword: confirmPassword,
      };
      try {
        const response = await axios.post("/api/account/resetpassword", body, {
          headers: { "Content-Type": "application/json" },
        });

        if (response.status === 200) {
          navigate("/auth/sign-in");
        }
      } catch (error) {
        // Handle error response
      }
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[16vh] w-full max-w-full flex-col items-center lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Ndryshoni fjalëkalimin
        </h4>
        <p className="mb-8 ml-1 text-base text-gray-600">
          Ju lutemi plotësoni fushat e kërkuara për ndryshimin e fjalëkalimit!
        </p>
        <div className="mb-6 flex items-center gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        <label htmlFor="password">
          Fjalekalimi i ri
        </label>
        <input
          className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm  outline-none"
          placeholder="Min. 8 karaktere"
          id="password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />
        <br />
        <label  htmlFor="confirmPassword">
          Konfirmo Fjalekalimin
        </label>
        <input
          className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
          placeholder="Min. 8 karaktere"
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
        />
        {passwordError && <p className="text-red-500">{passwordError}</p>}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center"></div>
        </div>
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-1000 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-1000 active:bg-brand-1000 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleSubmit}
        >
          Ndrysho
        </button>
      </div>
    </div>
  );
}
