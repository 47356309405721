import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import jwtDecode from "jwt-decode";

const RequireAuth = ({ allowedRole }) => {
    const { auth } = useAuth();

    let roles;
    let confirmRoles;
    const token = localStorage.getItem("token");

    if (token !== null) {
      confirmRoles = jwtDecode(token).Role
    }
    if (auth?.roles === undefined) {
      roles = confirmRoles;
    } else {
      roles = auth?.roles;
    }
  
    const location = useLocation();
  
    const isAuthenticated = (auth && auth.accessToken) || (token !== null);
    const hasAllowedRole = roles === allowedRole ? true : false;
  
    if (isAuthenticated) {
      if (hasAllowedRole) {
        return <Outlet />;
      } else {
        return (
          <Navigate
            to="/unauthorized"
            state={{ from: location }}
            replace
          />
        );
      }
    } else {
      return (
        <Navigate
          to="/auth/sign-in"
          state={{ from: location }}
          replace
        />
      );
    }
  };
export default RequireAuth;