import React, { useState, useEffect } from "react";
import axios from "../../../../api/axios";
import { MdOutlineCalendarToday } from "react-icons/md";
import Card from "components/card";
import {
  lineChartOptionsTotalSpent,
} from "variables/charts";
import LineChart from "components/charts/LineChart";

const TotalSpent = () => {
  const [graphData, setGraphData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token");

      let token = "Bearer " + storedToken.replace(/"/g, "");
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get(
          "/api/dashboard/getgraph",
          { headers }
        );
        setGraphData(response.data);
      } catch (error) {
      }
    };
    fetchData();
  }, []);

  const data = [
    {
      name: "Komunikacioni",
      data: graphData["communication"],
      color: "#4318FF",
    },
    {
      name: "Dhuna",
      data: graphData["violence"],
      color: "#6AD2FF",
    },
  ];
  return (
    <Card extra="!p-[20px] text-center">
      <div className="flex justify-between">
        <button className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80">
          <MdOutlineCalendarToday />
          <span className="text-sm font-medium text-gray-600">
            Raporti 1 vjetor
          </span>
        </button>
      </div>

      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex flex-col">
          <p className="mt-[20px] text-2xl font-bold text-navy-700 dark:text-white">
            {graphData["totalReports"]}
          </p>
          <div className="flex flex-col items-start">
            <p className="mt-2 text-sm text-gray-600">Raportimet</p>
          </div>
        </div>
        <div className="h-full w-full">
          <LineChart options={lineChartOptionsTotalSpent} series={data} />
        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;
