import React, { useMemo, useState } from "react";
import Card from "components/card";
import axios from "../../../../api/axios";
import { useNavigate } from "react-router-dom";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

const CheckTableDashboard = (props) => {
  const { columnsData, tableData } = props;
  const [showModal, setShowModal] = React.useState(false);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => {
    const reversedData = [...tableData].reverse();
    return reversedData;
  }, [tableData]);
  const [selectedReport, setSelectedReport] = React.useState(null);
  // const [isRewardSelected, setIsRewardSelected] = useState(false);


  const navigate = useNavigate();

  const handleRedirect = () => {
    // Replace "/other-page" with the desired destination page path
    navigate("/admin/report");
  };

  const fetchReportDetails = async (reportId) => {
    const storedToken = localStorage.getItem("token");
    const token = "Bearer " + storedToken.replace(/"/g, "");
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(
        `/api/reports/report/${reportId}`,
        { headers }
      );
      setSelectedReport(response.data);
      setShowModal(true);
    } catch (error) {
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  // const combinedMedia = [
  //   ...(selectedReport?.photos || []),
  //   ...(selectedReport?.videos || []),
  // ];
  // const [selectedMedia, setSelectedMedia] = useState(null);

  // const isVideo = (media) =>
  //   media.endsWith(".mp4") || media.endsWith(".avi") || media.endsWith(".mov");

  return (
    <Card extra={"w-full sm:overflow-auto p-4"}>
      <header className="relative flex lg: px-2 items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Lista e tre raporteve të fundit
        </div>
        <button
          className="text-sm font-medium text-brand-500 hover:text-brand-700"
          onClick={handleRedirect}
        >
          Shiko të gjitha
        </button>
     </header>

      <div className="mt-8 w-full overflow-x-scroll xl:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] pr-16 text-start dark:!border-navy-700" 
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";

                    if (cell.column.Header === "Emri i raportuesit") {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "Email adresa") {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "Kategoria") {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    }else if (cell.column.Header === "Qyteti") {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    }  
                    else if (cell.column.Header === "Data dhe ora") {
                      const date = new Date(cell.value);
                      const formattedDate = `${date.getFullYear()}-${(
                        date.getMonth() + 1
                      )
                        .toString()
                        .padStart(2, "0")}-${date
                        .getDate()
                        .toString()
                        .padStart(2, "0")} ${date
                        .getHours()
                        .toString()
                        .padStart(2, "0")}:${date
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")}`;
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {formattedDate}
                        </p>
                      );
                   } 
                   //else if (cell.column.Header === "Shiko detajet") {
                    //   data = (
                    //     <div className="flex items-center">
                    //       <label
                    //         id="mymodal"
                    //         htmlFor="my-modal-5"
                    //         className=" rounded-xl bg-brand-500 px-[15px] py-[8px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    //         onClick={() => fetchReportDetails(row.original.id)}
                    //       >
                    //         {" "}
                    //         Detajet{" "}
                    //       </label>

                    //       <input
                    //         type="checkbox"
                    //         id="my-modal-5"
                    //         className="modal-toggle "
                    //       />
                    //       <div className="modal">
                    //         <div className="modal-box w-11/12 max-w-5xl bg-white">
                    //           <h3 className="flex justify-center text-lg font-bold text-brand-500">
                    //             Detajet e raportuesit!
                    //           </h3>
                    //           <hr />
                    //           <div className="mb-4 flex items-center pt-4">
                    //             <p className="text-black-500 text-lg font-bold dark:text-white">
                    //               Emri i raportuesit:
                    //             </p>
                    //             <p className="ml-2 text-lg text-black">
                    //               {selectedReport?.name}
                    //             </p>
                    //           </div>
                    //           <div className="mb-4 flex items-center">
                    //             <p className="text-lg font-bold dark:text-white">
                    //               Email adresa:
                    //             </p>
                    //             <p className="ml-2 text-lg text-black">
                    //               {selectedReport?.senderEmail}
                    //             </p>
                    //           </div>
                    //           <div className="mb-4 flex items-center">
                    //             <p className="text-lg font-bold dark:text-white">
                    //               Kategoria:
                    //             </p>
                    //             <p className="ml-2 text-lg text-black">
                    //               {selectedReport?.category}
                    //             </p>
                    //           </div>
                    //           <div className="mb-4 flex items-center">
                    //             <p className="text-lg font-bold dark:text-white">
                    //               Data:
                    //             </p>
                    //             <p className="ml-2 text-lg text-black">
                    //               {selectedReport?.date}
                    //             </p>
                    //           </div>
                    //           <div className="mb-4 flex items-center">
                    //             <p className="text-lg font-bold dark:text-white">
                    //               Pershkrimi:
                    //             </p>
                    //             <p className="ml-2 text-lg text-black">
                    //               {selectedReport?.textContent}
                    //             </p>
                    //           </div>
                    //           <div className="carousel w-full">
                    //             {combinedMedia.length > 0 && (
                    //               <div className="carousel w-full">
                    //                 {combinedMedia.map((media, index) => (
                    //                   <div
                    //                     key={index}
                    //                     id={"slide" + index}
                    //                     className="carousel-item relative flex w-full justify-center"
                    //                   >
                    //                     {isVideo(media) ? (
                    //                       <video
                    //                         src={media}
                    //                         className="h-80 cursor-pointer"
                    //                         onClick={() =>
                    //                           setSelectedMedia(media)
                    //                         }
                    //                         controls
                    //                       />
                    //                     ) : (
                    //                       <img
                    //                         src={media}
                    //                         className="h-80 cursor-pointer"
                    //                         alt="..."
                    //                         onClick={() =>
                    //                           setSelectedMedia(media)
                    //                         }
                    //                       />
                    //                     )}
                    //                     <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
                    //                       <a
                    //                         href={
                    //                           "#slide" +
                    //                           (index === 0
                    //                             ? combinedMedia.length - 1
                    //                             : index - 1)
                    //                         }
                    //                         className="btn-circle btn"
                    //                       >
                    //                         ❮
                    //                       </a>
                    //                       <a
                    //                         href={
                    //                           "#slide" +
                    //                           ((index + 1) %
                    //                             combinedMedia.length)
                    //                         }
                    //                         className="btn-circle btn"
                    //                       >
                    //                         ❯
                    //                       </a>
                    //                     </div>
                    //                   </div>
                    //                 ))}
                    //               </div>
                    //             )}

                    //             {selectedMedia && (
                    //               <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-90">
                    //                 <div className="rounded-lg bg-white p-5">
                    //                   {isVideo(selectedMedia) ? (
                    //                     <video
                    //                       src={selectedMedia}
                    //                       alt="..."
                    //                       className="h-[40rem] w-[40rem]"
                    //                       controls
                    //                     />
                    //                   ) : (
                    //                     <img
                    //                       src={selectedMedia}
                    //                       alt="..."
                    //                       className="h-[40rem] w-[40rem]"
                    //                     />
                    //                   )}
                    //                   <button
                    //                     className="btn mt-4 border-0 bg-brand-500 text-white hover:bg-brand-700"
                    //                     onClick={() => setSelectedMedia(null)}
                    //                   >
                    //                     Kthehu
                    //                   </button>
                    //                 </div>
                    //               </div>
                    //             )}
                    //           </div>

                    //           <hr />
                    //           <footer className="modal-action ">
                    //             <label
                    //               htmlFor="my-modal-5"
                    //               className="btn-sm btn-circle btn absolute right-2 top-2"
                    //             >
                    //               ✕
                    //             </label>
                    //             <label
                    //               htmlFor="my-modal-5"
                    //               className="btn border-0 bg-white text-black hover:bg-white"
                    //             >
                    //               Kthehu
                    //             </label>
                    //             <label
                    //               htmlFor="my-modal-6"
                    //               className="btn border-0 bg-red-500 text-white hover:bg-red-600"
                    //             >
                    //               Refuzo
                    //             </label>
                    //             <label
                    //               htmlFor="my-modal-7"
                    //               className="btn border-0 bg-brand-500 text-white hover:bg-brand-700"
                    //             >
                    //               Aprovo
                    //             </label>
                    //           </footer>
                    //         </div>
                    //       </div>
                    //       {/* Refuzimi */}
                    //       <input
                    //         type="checkbox"
                    //         id="my-modal-6"
                    //         className="modal-toggle"
                    //       />
                    //       <div className="modal">
                    //         <div className="modal-box w-11/12 max-w-5xl bg-white">
                    //           <h3 className="flex justify-center text-lg font-bold">
                    //             Refuzim i raportit
                    //           </h3>
                    //           <hr />
                    //           <textarea
                    //             placeholder="Shkruaj arsyjen e refuzimit"
                    //             className="textarea-bordered textarea textarea-sm w-full bg-white"
                    //           ></textarea>

                    //           <footer className="modal-action ">
                    //             <label
                    //               htmlFor="my-modal-6"
                    //               className="btn-sm btn-circle btn absolute right-2 top-2"
                    //             >
                    //               ✕
                    //             </label>
                    //             <label
                    //               htmlFor="my-modal-6"
                    //               className="btn border-0 bg-brand-500 text-white hover:bg-brand-700"
                    //             >
                    //               Kthehu
                    //             </label>
                    //             <label
                    //               htmlFor="my-modal-6"
                    //               className="btn border-0 bg-red-500 text-white hover:bg-red-600"
                    //             >
                    //               Refuzo
                    //             </label>
                    //           </footer>
                    //         </div>
                    //       </div>
                    //       {/* Aprovimi */}
                    //       <input
                    //         type="checkbox"
                    //         id="my-modal-7"
                    //         className="modal-toggle"
                    //       />
                    //       <div className="modal">
                    //         <div className="modal-box w-11/12 max-w-5xl bg-white">
                    //           <h3 className="flex justify-center pb-4 text-lg font-bold text-black">
                    //             Aprovimi i raportit
                    //           </h3>
                    //           <hr />
                    //           <textarea
                    //             placeholder="Komento aprovimin.."
                    //             className="textarea-bordered textarea textarea-sm w-full bg-white text-black"
                    //           ></textarea>
                    //           <div className="form-control w-2/12 ">
                    //             <label className="text-body flex ps-0 pt-4 font-bold text-black">
                    //               Përfiton shpërblim?
                    //             </label>
                    //             <label className="label cursor-pointer">
                    //               <span className="label-text text-black">
                    //                 Po
                    //               </span>
                    //               <input
                    //                 type="radio"
                    //                 name="radio-10"
                    //                 className="radio checked:bg-brand-500"
                    //                 checked={isRewardSelected}
                    //                 onChange={() => setIsRewardSelected(true)}
                    //               />
                    //             </label>
                    //             <label className="label cursor-pointer">
                    //               <span className="label-text text-black">
                    //                 Jo
                    //               </span>
                    //               <input
                    //                 type="radio"
                    //                 name="radio-10"
                    //                 className="radio checked:bg-brand-500"
                    //                 checked={!isRewardSelected}
                    //                 onChange={() => setIsRewardSelected(false)}
                    //               />
                    //             </label>
                    //             {isRewardSelected && (
                    //               <input
                    //                 type="number"
                    //                 placeholder="Shuma e përfituar"
                    //                 className="mt-2 rounded-md border bg-white p-2 text-black"
                    //               />
                    //             )}
                    //           </div>

                    //           <footer className="modal-action ">
                    //             <label
                    //               htmlFor="my-modal-7"
                    //               className="btn-sm btn-circle btn absolute right-2 top-2"
                    //             >
                    //               ✕
                    //             </label>
                    //             <label
                    //               htmlFor="my-modal-7"
                    //               className="btn border-0 bg-white text-black hover:bg-white"
                    //             >
                    //               Kthehu
                    //             </label>
                    //             <label className="alert-info btn border-0 bg-brand-500 text-white hover:bg-brand-700">
                    //               Aprovo
                    //             </label>
                    //           </footer>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   );
                    // }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pb-[16px] pt-[14px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default CheckTableDashboard;
