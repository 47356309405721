import Chart from "react-apexcharts";

const LineChart = (props) => {
  const { series, options } = props;

  // Map over the series and change the color of each series
  const modifiedSeries = series.map((s, index) => ({
    ...s,
    color: index === 0 ? "#2AAA8A" : "#4F7942", // Set the color to red for the first series and green for the second series
  }));

  return (
    <Chart
      options={options}
      type="line"
      width="100%"
      height="100%"
      series={modifiedSeries}
    />
  );
};

export default LineChart;