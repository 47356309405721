    import { Outlet } from "react-router-dom";
    import { useState, useEffect } from "react";
    import useRefreshToken from '../hooks/useRefreshToken';
    import useAuth from '../hooks/useAuth';
    import logo from "assets/img/logo/logo.png";

    const PersistLogin = () => {
        const [isLoading, setIsLoading] = useState(true);
        const refresh = useRefreshToken();
        const { auth, persist } = useAuth();

        useEffect(() => {
            let isMounted = true;

            const verifyRefreshToken = async () => {
                try {
                    await refresh();
                }
                catch (err) {
                    console.error(err);
                }
                finally {
                    isMounted && setIsLoading(false);
                }
            }

            if (!auth?.accessToken && persist) {
                verifyRefreshToken();
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }

            return () => (isMounted = false);
        }, [auth?.accessToken, persist, refresh]);

        return (
            <>
                {!persist ? 
                    <Outlet />
                       : isLoading ? 
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                                <img className="h-20 m-auto" src={logo} alt="Logo" />
                            </div>
                         : 
                            <Outlet />
                
                }
            </>
        );
       
    }

    export default PersistLogin;
