
import {columnsDataComplex} from "./variables/columnsData";
import ComplexTable from "./components/ComplexTable";
import React, { useState, useEffect } from 'react';
import axios from '../../../api/axios';

const Verification = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token"); 
      
      let token = "Bearer " + storedToken.replace(/"/g, '');
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get(
          "/api/verification/users",
          { headers }
        );
        setData(response.data);
      } catch (error) {
      }
    };
    fetchData();
  }, []);
  return (
    <div>

      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={data}
        />
      </div>
    </div>
  );
};

export default Verification;
