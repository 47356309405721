import ReactDOM from "react-dom/client";
import { AuthProvider } from "context/AuthProvider";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import "./index.css";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
  <AuthProvider>
    <Routes>
      <Route path="/*" element={<App />} />
    </Routes>
  </AuthProvider>
</BrowserRouter>
);
