import React, { useState, useEffect } from "react";
import Card from "components/card";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "../../../../api/axios";
import PaymentDetails from "./PaymentDetails";
import { TailSpin } from "react-loader-spinner";

const CheckTableTransaction = (props) => {
  const [activeTab, setActiveTab] = useState("first");
  const [rows, setRows] = useState([]);
  const [row, setRow] = useState([]);
  const [paymentModel, setpaymentModel] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchPaymentDetails = async (transactionId) => {
    const storedToken = localStorage.getItem("token");
    const token = "Bearer " + storedToken.replace(/"/g, "");
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.get(`/api/transaction/${transactionId}`, {
        headers,
      });
      setpaymentModel(response.data);
    } catch (error) {}
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token");
      const token = "Bearer " + storedToken.replace(/"/g, "");
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get("/api/transaction", { headers });
        setRows(response.data);
        setLoading(false);
      } catch (error) {}
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token");

      let token = "Bearer " + storedToken.replace(/"/g, "");
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get("/api/transactions/completed", {
          headers,
        });
        setRow(response.data);
        setLoading(false);
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    fetchPaymentDetails();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderTable = () => {
    const customLocaleText = {
      noRowsLabel: "Nuk ka pagesa në proces",
      footerRowSelected: (count) =>
        `${count === 1 ? "1 rresht" : `${count} rreshta`} i selektuar`,
    };

    if (activeTab === "first") {
      const columns = [
        {
          field: "user",
          headerName: <b>Emri i përdoruesit</b>,
          flex: 1,
          minWidth: 180,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "bankName",
          headerName: <b>Emri i bankës</b>,
          flex: 1,
          minWidth: 180,
          align: "center",
          headerAlign: "center",
          editable: false,
        },
        {
          field: "amount",
          headerName: <b>Shuma e transferit</b>,
          flex: 1,
          minWidth: 180,
          align: "center",
          headerAlign: "center",
          editable: false,
          valueFormatter: ({ value }) => {
            return value + " €";
          },
        },
        {
          field: "date",
          headerName: <b>Data e raportimit</b>,
          flex: 1,
          minWidth: 180,
          align: "center",
          headerAlign: "center",
          editable: false,
          valueFormatter: ({ value }) => {
            return new Date(value).toLocaleString("en-US", {
              dateStyle: "short",
              timeStyle: "short",
            });
          },
        },
        {
          field: "detajet",
          headerName: <b>Shiko detajet</b>,
          id: "mymodal1",
          flex: 0.7,
          minWidth: 150,
          align: "center",
          headerAlign: "center",
          renderCell: (params) => (
            <label
              id="mymodal1"
              htmlFor="my-modal-5"
              className="rounded-xl bg-brand-500 px-[15px] py-[8px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={() => fetchPaymentDetails(params.id)}
            >
              Detajet
            </label>
          ),
        },
      ];
      return (
        <DataGrid
          rows={rows}
          columns={columns}
          className="text-black dark:text-white"
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableSelectionOnClick
          disableColumnMenu
          localeText={customLocaleText}
        />
      );
    } else if (activeTab === "second") {
      const columns = [
        {
          field: "user",
          headerName: <b>Emri i përdoruesit</b>,
          flex: 1,
          minWidth: 180,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "bankName",
          headerName: <b>Emri i bankës</b>,
          flex: 1,
          minWidth: 150,
          align: "center",
          headerAlign: "center",
          editable: false,
        },
        {
          field: "amount",
          headerName: <b>Shuma e transferit</b>,
          flex: 1,
          minWidth: 170,
          align: "center",
          headerAlign: "center",
          editable: false,
          valueFormatter: ({ value }) => {
            return value + " €";
          },
        },
        {
          field: "sendDate",
          headerName: <b>Data e dërgimit</b>,
          flex: 1,
          minWidth: 170,
          align: "center",
          headerAlign: "center",
          editable: false,
          valueFormatter: ({ value }) => {
            return new Date(value).toLocaleString("en-US", {
              dateStyle: "short",
              timeStyle: "short",
            });
          },
        },
        {
          field: "completedDate",
          headerName: <b>Data e aprovimit</b>,
          flex: 1,
          minWidth: 170,
          align: "center",
          headerAlign: "center",
          editable: false,
          valueFormatter: ({ value }) => {
            return new Date(value).toLocaleString("en-US", {
              dateStyle: "short",
              timeStyle: "short",
            });
          },
        },
        {
          field: "status",
          headerName: <b>Statusi</b>,
          flex: 1,
          minWidth: 150,
          align: "center",
          headerAlign: "center",
          editable: false,
        },
        {
          field: "reviewdBy",
          headerName: <b>Emri i zyrtarit</b>,
          flex: 1,
          minWidth: 180,
          align: "center",
          headerAlign: "center",
          editable: false,
        },
      ];
      return (
        <DataGrid
          rows={row}
          columns={columns}
          className="text-black dark:text-white"
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableSelectionOnClick
          disableColumnMenu
          localeText={customLocaleText}
        />
      );
    }
  };

  return (
    <>
      {loading ? (
        <div className="mt-11 flex items-center justify-center pt-11">
          <TailSpin bg-brand-500 height={70} width={70} />
        </div>
      ) : (
        <Card extra="w-full sm:overflow-auto p-4">
          <ul className="flex flex-wrap border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400">
            <li className="mr-2">
              <button
                className={`${
                  activeTab === "first"
                    ? "active inline-block rounded-t-lg bg-gray-100 p-4 text-brand-600 dark:bg-gray-800 dark:text-brand-500"
                    : "inline-block rounded-t-lg p-4 hover:bg-gray-50 hover:text-gray-600 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                }`}
                onClick={() => handleTabChange("first")}
              >
                Transaksionet në pritje
              </button>
            </li>
            <li className="mr-2">
              <button
                className={`${
                  activeTab === "second"
                    ? "active inline-block rounded-t-lg bg-gray-100 p-4 text-brand-600 dark:bg-gray-800 dark:text-brand-500"
                    : "inline-block rounded-t-lg p-4 hover:bg-gray-50 hover:text-gray-600 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                }`}
                onClick={() => handleTabChange("second")}
              >
                Transaksionet e përfunduara
              </button>
            </li>
          </ul>
          <Box mt={4}>
            {renderTable() || (
              <div className="mt-4 text-center">
                <CircularProgress />
              </div>
            )}
          </Box>
          <PaymentDetails paymentModel={paymentModel} />
        </Card>
      )}
    </>
  );
};

export default CheckTableTransaction;
