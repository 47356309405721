import Registerform from "./components/registerform";

const Registerformzyrtar = () => {
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-1">
        <div className="col-span-4 lg:!mb-0">
          <Registerform />
        </div>
      </div>
     
    </div>
  );
};

export default Registerformzyrtar;
