import React from "react";

// Admin Imports
import MainDashboard from "views/admin/dashboard";
import Profile from "views/admin/profile/profile";
import Administrator from "views/admin/profile/administrator";
import Registerform from "views/admin/profile/registeradministrator";
import Payment from "views/admin/payment";
import Verification from "views/admin/verification";
import NewRepo from "views/admin/raport/newRepo";
import OldRepo from "views/admin/raport/oldRepo";
import Forgotpass from "views/auth/Forgotpass";
import Changedata from "../src/views/admin/profile/components/changedata";
import Editpass from "../src/views/admin/profile/components/editpass";
import Userdetails from "../src/views/admin/profile/components/userdetails";
import Pricereview from "../src/views/admin/profile/components/Pricereview";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdPerson,
  MdLock,
  MdPeople,
  MdOpenInNew,
  MdVerifiedUser,
  MdPayment,
  MdMenuOpen,
  MdPersonAdd,
  MdPriceCheck,
} from "react-icons/md";
import NewPassword from "views/auth/NewPassword";
import CheckEmail from "views/auth/CheckEmail";
import VerificationEmail from "views/auth/verificationEmail";
import VerificationEmailFlutter from "views/auth/verificationEmailFlutter";

const routes = [
  {
    name: "Paneli",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },

  {
    name: "Raportet e reja",
    layout: "/admin",
    icon: <MdOpenInNew className="h-6 w-6" />,
    path: "report",
    component: <NewRepo />,
  },
  {
    name: "Raportet e shikuara",
    layout: "/admin",
    icon: <MdMenuOpen className="h-6 w-6" />,
    path: "oldrepo",
    component: <OldRepo />,
  },
  // {
  //   name: "Raportet",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   subLinks: [
  //     {
  //       name: "Raportet e reja",
  //       layout: "/admin",
  //       path: "report",
  //       component: <NewRepo />,
  //     },
  //     {
  //       name: "Raportet e shikuara",
  //       path: "report",
  //       component: <OldRepo />,
  //     },

  //   ],
  //   component: <DataTables />,
  // },

  {
    name: "Verifiko përdoruesin",
    layout: "/admin",
    icon: <MdVerifiedUser className="h-6 w-6" />,
    path: "verification",
    component: <Verification />,
  },
  {
    name: "Pagesat",
    layout: "/admin",
    icon: <MdPayment className="h-6 w-6" />,
    path: "payment",
    component: <Payment />,
  },
  {
    name: "Administratorët",
    layout: "/admin",
    path: "administrator",
    icon: <MdPeople className="h-6 w-6" />,
    component: <Administrator />,
  },
  {
    name: "Regjistro zyrtarë",
    layout: "/admin",
    path: "registerform",
    icon: <MdPersonAdd className="h-6 w-6 " />,
    component: <Registerform />,
  },
  {
    name: "Kalkulo shpërblimin",
    layout: "/admin",
    path: "review-price",
    icon: <MdPriceCheck className="h-6 w-6 " />,
    component: <Pricereview />,
  },
  {
    name: "Profili",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Kyçu",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6 " />,
    component: <SignIn />,
  },
  {
    name: "Ndrysho fjalkalimin",
    layout: "/auth",
    path: "forgot-pass",
    icon: <MdLock className="h-6 w-6 " />,
    component: <Forgotpass />,
  },
  {
    name: "Ndrysho fjalkalimin",
    layout: "/auth",
    path: "password",
    icon: <MdLock className="h-6 w-6" />,
    component: <NewPassword />,
  },
  {
    name: "Shiko Emailin",
    layout: "/auth",
    path: "check-email",
    icon: <MdLock className="h-6 w-6 " />,
    component: <CheckEmail />,
  },
  {
    name: "Verifikimi i emailit",
    layout: "/auth",
    path: "verificationEmail",
    icon: <MdLock className="h-6 w-6 " />,
    component: <VerificationEmail />,
  },
  {
    name: "Verifikimi i emailit flutter",
    layout: "/auth",
    path: "verificationEmailFlutter",
    icon: <MdLock className="h-6 w-6 " />,
    component: <VerificationEmailFlutter />,
  },
  {
    name: "Ndrysho profilin",
    layout: "/admin",
    path: "edit-profile",
    icon: <MdLock className="h-6 w-6 " />,
    component: <Changedata />,
  },
  {
    name: "Ndrysho fjalëkalimin",
    layout: "/admin",
    path: "edit-pass",
    icon: <MdLock className="h-6 w-6 " />,
    component: <Editpass />,
  },
  {
    name: "Ndrysho të dhënat e zyrtarit",
    layout: "/admin",
    path: "userdetails",
    icon: <MdLock className="h-6 w-6 " />,
    component: <Userdetails />,
  },
];
export default routes;
