import { React, useState } from "react";
import axios from "../../../../api/axios";
import Swal from "sweetalert2";

const Approve = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [amountValue, setAmountValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const storedToken = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  let token = "Bearer " + storedToken.replace(/"/g, "");
  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };

  const sendTextValue = () => {
    if (!selectedOption) {
      setErrorMessage("Ju lutemi zgjedhni njërën nga arsyjet e aprovimit!");
      return;
    }
    const body = {
      adminId: userId,
      reportId: props.id,
      description: selectedOption,
      approved: true,
      amount: amountValue === "" ? 0 : amountValue,
    };
    axios
      .post("/api/reports/approve", body, {
        headers: headers,
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Raportimi është aprovuar me sukses!",
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
          timer: 2500,
        });
        setTimeout(() => {
          window.location.href = "/admin/report";
        }, 2500);
      })

      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "Diçka shkoi keq! Ju lutemi provoni përseri",
          timer: 2500,
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
        });
      });
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    setErrorMessage("");
  };

  const handleAmountChange = (event) => {
    setAmountValue(event.target.value);
  };
  return (
    <>
      <input type="checkbox" id="my-modal-7" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-5xl bg-white">
          <h3 className="flex justify-center pb-4 text-lg font-bold">
            Aprovimi i raportit
          </h3>
          <hr />
          <select
            className="select-bordered select select-sm w-full bg-white"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option value="" disabled>
              Zgjedhni arsyjen e aprovimit:
            </option>
            <option value="Raporti është aprovuar, raporti i ngjashëm është dërguar më herët">
              Raporti është aprovuar, raporti i ngjashëm është dërguar më herët
            </option>
            <option value="Raporti është aprovuar, ju do të shpërbleheni për raportin e dërguar">
              Raporti është aprovuar, ju do të shpërbleheni për raportin e
              dërguar
            </option>
            <option value="Raporti është aprovuar, per pak minuta do të kontaktoheni nga zyrtarët policor">
              Raporti është aprovuar, per pak minuta do të kontaktoheni nga
              zyrtarët policor
            </option>
          </select>

          {errorMessage && (
            <div className="error-message text-red-500">{errorMessage}</div>
          )}

          <footer className="modal-action ">
            <label
              htmlFor="my-modal-7"
              className="btn-sm btn-circle btn absolute right-2 top-2"
            >
              ✕
            </label>
            <label
              htmlFor="my-modal-7"
              className="btn border-0 bg-white text-brand-700 hover:text-white"
            >
              Kthehu
            </label>
            <label
              onClick={sendTextValue}
              className="alert-info btn border-0 bg-brand-500 text-white hover:bg-brand-700"
            >
              Aprovo
            </label>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Approve;
