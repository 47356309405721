import Card from "components/card";
import React, { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { useMemo } from "react";
import ReviewdReportDetails from "./ReviewedReportDetails";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { TailSpin } from "react-loader-spinner";

const ComplexTable = (props) => {
  const { tableData } = props;
  const [selectedReport, setSelectedReport] = React.useState(null);
  const data = useMemo(() => tableData, [tableData]);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const fetchReportDetails = async (reportId) => {
    const storedToken = localStorage.getItem("token");
    const token = "Bearer " + storedToken.replace(/"/g, "");
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(
        `/api/reports/reviewedreport?reportId=${reportId}`,
        { headers }
      );
      setSelectedReport(response.data);
    } catch (error) {}
  };

  const customLocaleText = {
    noRowsLabel: "Nuk ka raporte në proces",
    footerRowSelected: (count) =>
      `${count === 1 ? "1 rresht" : `${count} rreshta`} i selektuar`,
  };

  const columns = [
    {
      field: "name",
      headerName: <b>Emri dhe mbiemri</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "date",
      headerName: <b>Data e raportimit</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
      valueFormatter: ({ value }) => {
        return new Date(value).toLocaleString("en-US", {
          dateStyle: "short",
          timeStyle: "short",
        });
      },
    },
    {
      field: "status",
      headerName: <b>Statusi</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
      renderCell: (params) => (
        <div className="flex items-center gap-2">
          <div className={`rounded-full text-xl`}>
            {params.value === true ? (
              <div className="flex">
                {" "}
                <MdCheckCircle className="text-green-500" />
                <p className="text-sm text-navy-700 dark:text-white">
                  Aprovuar
                </p>
              </div>
            ) : params.value === false ? (
              <div className="flex">
                <MdCancel className="text-red-500" />
                <p className="text-sm text-navy-700 dark:text-white">
                  Refuzuar
                </p>
              </div>
            ) : null}
          </div>
          <p className="text-sm text-navy-700 dark:text-white">
            {params.value}
          </p>
        </div>
      ),
    },

    {
      field: "category",
      headerName: <b>Kategoria</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "detajet",
      headerName: <b>Shiko detajet</b>,
      id: "mymodal",
      flex: 0.7,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <label
          id="mymodal"
          htmlFor="my-modal-5"
          className="rounded-xl bg-brand-500 px-[15px] py-[8px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={() => fetchReportDetails(params.row.id)}
        >
          Detajet
        </label>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token");

      let token = "Bearer " + storedToken.replace(/"/g, "");
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get("/api/reports/reviewdreports", {
          headers,
        });
        setRows(response.data.reverse());
        setLoading(false);
      } catch (error) {}
    };
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="mt-11 flex items-center justify-center pt-11">
          <TailSpin bg-brand-500 height={70} width={70} />
        </div>
      ) : (
        <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
          <div className="relative flex items-center justify-between">
            <div className="pb-2 text-xl font-bold text-navy-700 dark:text-white">
              Të gjitha raportet e shikuara
            </div>
          </div>
          <ReviewdReportDetails selectedReport={selectedReport} />
          <Box>
            <DataGrid
              rows={rows}
              columns={columns}
              className="text-black dark:text-white"
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableSelectionOnClick={true}
              disableColumnMenu
              localeText={customLocaleText}
            />
          </Box>
        </Card>
      )}
    </>
  );
};

export default ComplexTable;
