import logo from "assets/img/auth/done.png";

export default function CheckEmail() {
  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[16vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h6 className="mb-2.5 text-3xl font-bold text-navy-700 dark:text-white">
        Sapo i'u dërguam shtegun për rikrijimin e fjalëkalimit! 
        </h6>
        <p className="mb-8 ml-1 text-base text-gray-600">
         
          Ju lutemi kontrolloni adresën elektronike me të cilën i'u është hapur llogaria në m-Repo!
        </p>
        <div className="mb-6 flex items-center gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        <div className="mb-6 pt-4 flex items-center gap-3">
          <img className="h-20 m-auto" src={logo} alt="Logo" />
        </div>

      </div>
    </div>
  );
}
