import React, { useState, useEffect } from "react";
import Card from "components/card";
import axios from "../../../../api/axios";
import { TailSpin } from 'react-loader-spinner'

const Banner = () => {
  const obj = {
    model: {
      name: "",
      email: "",
      phone: "",
      city: "",
      institution: "",
      role: "",
      pictureUrl: "",
    },
    picture: "",
  };
  const [data, setData] = useState(obj);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");
      let token = "Bearer " + storedToken.replace(/"/g, "");
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get(
          `/api/account/getadministrator?userId=${userId}`,
          { headers }
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        const refreshToken = localStorage.getItem("refreshToken");
        const body = {
          refreshToken,
          userId,
        };
        const response = await axios.post("/api/refresh-token", body, {
          headers: { "Content-Type": "application/json" },
        });
        var secondtoken = localStorage.setItem(
          "token",
          JSON.stringify(response.data)
        );

        let token = "Bearer " + secondtoken.replace(/"/g, "");
        const headers = {
          Authorization: token,
          "Content-Type": "application/json",
        };
        if (token != null) {
          const response = await axios.get(
            `/api/account/getadministrator?userId=${userId}`,
            { headers }
          );
        }
        setLoading(false); 
      }
    };
    fetchData();
  }, []);

  return (
    <>
    {loading ? (
      <div className="flex items-center justify-center pt-11 mt-11">
        <TailSpin bg-brand-500 height={70} width={70} />
      </div>
    ) : (
    <Card extra={" w-full h-full p-[16px] bg-cover"}>
      <header className="items-start">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Të dhënat personale
        </div>
        <hr />
      </header>
      <div className="relative flex h-28 w-full justify-center rounded-xl bg-cover">
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white dark:!border-navy-700">
          <img
            className="h-full w-full rounded-full"
            src={data["picture"]}
            alt=""
          />
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
          {data["model"]["name"]}
        </h4>
        <p className="text-base font-normal text-gray-600">
          {data["model"]["role"]}
        </p>
      </div>

      {/* Post followers */}
      <div className="mb-3 mt-6 gap-4 md:!gap-14">
        <div className="mb-2 flex flex-row items-center justify-center gap-2">
          <p className="text-1xl font-normal text-gray-600">Email adresa:</p>
          <p className="text-1xl font-bold text-navy-700 dark:text-white">
            {data["model"]["email"]}
          </p>
        </div>
        <div className="mb-2 flex flex-row items-center justify-center gap-2">
          <p className="text-1xl font-normal text-gray-600">Nr. telefonit:</p>
          <p className="text-1xl font-bold text-navy-700 dark:text-white">
            {data["model"]["phone"]}
          </p>
        </div>
        <div className="mb-2 flex flex-row items-center justify-center gap-2">
          <p className="text-1xl font-normal text-gray-600">Qyteti:</p>
          <p className="text-1xl font-bold text-navy-700 dark:text-white">
            {data["model"]["city"]}
          </p>
        </div>
        <div className="mb-2 flex flex-row items-center justify-center gap-2">
          <p className="text-1xl font-normal text-gray-600">Institucioni:</p>
          <p className="text-1xl font-bold text-navy-700 dark:text-white">
            {data["model"]["institution"]}
          </p>
        </div>
      </div>
    </Card>
        )}
        </>
  );
};

export default Banner;
