

export const columnsDataCheck = [
  {
    Header: "Emri i raportuesit",
    accessor: "name",
  },
  {
    Header: "Data dhe ora",
    accessor: "date",
  },
  {
    Header:"Qyteti",
    accessor:"address"
    },
  {
  Header:"Kategoria",
  accessor:"category"
  },
  {
    Header: "Email adresa",
    accessor: "senderEmail"
  },

];
export const columnsDataComplex = [
  {
    Header: "Emri dhe mbiemri",
    accessor: "name",
  },
  {
    Header: "Data e raportimit",
    accessor: "date",
  },
  {
    Header: "Kategoria",
    accessor: "category"
  },
 
  {
    Header: "Statusi",
    accessor: "status",
  },
  {
    Header: "Shiko detajet",
    accessor: "detajet"
  },
 
 
];
