import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import ForgotPass from "views/auth/Forgotpass";
import PersistLogin from "./components/PersistLogin";
import RequireAuth from "./components/RequireAuth";
const App = () => {
  return (
    <Routes>
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path="/forgot-password/" element={<ForgotPass />} />
      <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth allowedRole={"admin"} />}>
          <Route path="/admin/*" element={<AdminLayout />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
