import Card from "components/card";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "../../../../api/axios";
import { TailSpin } from "react-loader-spinner";

const Registerform = () => {
  const [municipalities, setMunicipalities] = useState([]);
  const [municipalityValue, setMunicipalityValue] = useState("");

  const [neighborhood, setNeighborhood] = useState([]);
  const [neighborhoodValue, setNeighborhoodValue] = useState("");

  const [institucion, setInstitucion] = useState([]);
  const [institucionValue, setInstitucionValue] = useState("");

  const [role, setRoles] = useState([]);
  const [roleValue, setRoleValue] = useState("");

  const [loading, setLoading] = useState(true);

  const storedToken = localStorage.getItem("token");
  let token = "Bearer " + storedToken.replace(/"/g, "");

  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    fetchCities();
    fetchInstitutions();
    setLoading(false);
  }, []);

  const fetchCities = async () => {
    const response = await axios.get("/api/municipalities", {
      headers: headers,
    });
    setMunicipalities(response.data);
  };

  const handleMunicipalityChange = (event) => {
    const selectedId = event.target.value;
    setMunicipalityValue(selectedId);
    fetchNeigborhoods(selectedId);
  };

  const fetchNeigborhoods = async (muniId) => {
    const response = await axios.get(`/api/neighborhoodbymuni?id=${muniId}`, {
      headers: headers,
    });
    setNeighborhood(response.data);
    setNeighborhoodValue("");
  };

  const handleNeighborhoodChange = (event) => {
    const selectedId = event.target.value;
    setNeighborhoodValue(selectedId);
  };

  const fetchInstitutions = async () => {
    const response = await axios.get("/api/Institutions", { headers: headers });
    setInstitucion(response.data);
  };

  const handleInstitutionsChange = (event) => {
    const name = event.target.value;
    setInstitucionValue(name);
    fetchRoles(name);
  };

  const fetchRoles = async (name) => {
    const response = await axios.get(`/api/roles?institution=${name}`, {
      headers: headers,
    });

    setRoles(response.data);
    setRoleValue("");
  };

  const handleRoleChange = (event) => {
    const selectedId = event.target.value;
    setRoleValue(selectedId);
  };

  const [emri, setEmri] = useState("");
  const [mbiemri, setMbiemri] = useState("");
  const [email, setEmail] = useState("");
  const [telefoni, setTelefoni] = useState("");

  // Error consts for inputs
  const [nameError, setNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const validateName = () => {
    if (emri.length < 2) {
      setNameError("Emri duhet te ketë dy e më shumë shkronja!");
    } else if (/[^\p{L}]/u.test(emri)) {
      setNameError("Ju lutem shkruani vetem shkronja!");
    } else {
      setNameError("");
    }
  };

  const validateSurname = () => {
    if (mbiemri.length < 2) {
      setSurnameError("Mbiemri duhet te ketë dy e më shumë shkronja!");
    } else if (/[^\p{L}]/u.test(mbiemri)) {
      setSurnameError("Ju lutem shkruani vetem shkronja!");
    } else {
      setSurnameError("");
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setEmailError("Emaili juaj nuk është në rregull!");
    } else {
      setEmailError("");
    }
  };

  const validatePhone = () => {
    if (telefoni.length < 9 || /\D/.test(telefoni)) {
      setPhoneError("Numri i telefonit mund të ketë nga 9 deri në 13 numra!");
    } else {
      setPhoneError("");
    }
  };

  const registerAdmin = () => {
    if (emri.length < 2 || emri.includes("@")) {
      setNameError("Keni plotesuar te dhenat gabim!");
      return;
    }

    if (mbiemri.length < 2) {
      setSurnameError("Keni plotesuar te dhenat gabim!");
      return;
    }

    if (email.length < 2) {
      setEmailError("Emaili juaj nuk është në rregull!");
      return;
    }

    if (telefoni.length < 2) {
      setPhoneError("Keni plotesuar te dhenat gabim");
      return;
    }

    const body = {
      firstName: emri,
      lastName: mbiemri,
      email: email,
      phoneNumber: telefoni,
      municipality: municipalityValue,
      neighborhood: neighborhoodValue,
      instition: institucionValue,
      role: roleValue,
    };

    axios
      .post("https://api.m-repo.info/api/account/addadmin", body, { headers })
      .then(() => {
        Swal.fire({
          icon: "success",
          text: "Administratori është regjistruar me sukses!",
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
          timer: 2500,
        });

        setTimeout(() => {
          window.location.href = "/admin/dashboard";
        }, 2500);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "Ju lutemi plotësoni të gjitha të dhënat e kërkuara.",
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          timer: 2500,
        });
      });
  };

  return (
    <>
      {loading ? (
        <div className="mt-11 flex items-center justify-center pt-11">
          <TailSpin bg-brand-500 height={70} width={70} />
        </div>
      ) : (
        <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
          <div className="mt-[4vh] w-full flex-col items-center md:pl-4 lg:pl-0 ">
            <div className="flex flex-wrap">
              <div className="mb-3 w-full md:w-1/2 md:flex-col md:pr-2">
                <label htmlFor="emri" className="dark:text-white">
                  {" "}
                  Emri{" "}
                </label>
                <input
                  placeholder="Shkruani emrin"
                  id="name"
                  type="text"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:text-white md:mr-2`}
                  name="emri"
                  value={emri}
                  onChange={(event) => setEmri(event.target.value)}
                  onBlur={validateName}
                />
                {nameError && (
                  <p>
                    {" "}
                    <i style={{ fontSize: "14px", color: "red" }}>
                      {nameError}
                    </i>{" "}
                  </p>
                )}
              </div>
              <div className="mb-3 w-full md:w-1/2 md:flex-col md:pr-2">
                <label htmlFor="mbiemri" className=" dark:text-white">
                  {" "}
                  Mbiemri{" "}
                </label>
                <input
                  placeholder="Shkruani mbiemrin"
                  id="surname"
                  type="text"
                  name="mbiemri"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:text-white `}
                  value={mbiemri}
                  onChange={(event) => setMbiemri(event.target.value)}
                  onBlur={validateSurname}
                />
                {surnameError && (
                  <p>
                    <i style={{ fontSize: "14px", color: "red" }}>
                      {surnameError}
                    </i>
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="mb-3 w-full md:w-1/2 md:flex-col md:pr-2">
                <label htmlFor="email" className="dark:text-white">
                  {" "}
                  Email{" "}
                </label>
                <input
                  placeholder="Shkruani emailin adresen"
                  id="email"
                  type="email"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:text-white `}
                  name="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  onBlur={validateEmail}
                />
                {emailError && (
                  <p>
                    {" "}
                    <i style={{ fontSize: "14px", color: "red" }}>
                      {emailError}
                    </i>{" "}
                  </p>
                )}
              </div>
              <div className="mb-3 w-full md:w-1/2 md:flex-col md:pr-2">
                <label htmlFor="phone" className=" dark:text-white">
                  Nr. telefonit
                </label>
                <input
                  placeholder="Shkruani numrin e telefonit"
                  id="phonenumber"
                  type="number"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:text-white `}
                  name="phone"
                  value={telefoni}
                  onChange={(event) => setTelefoni(event.target.value)}
                  onBlur={validatePhone}
                />
                {phoneError && (
                  <p>
                    {" "}
                    <i style={{ fontSize: "14px", color: "red" }}>
                      {phoneError}
                    </i>{" "}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="mb-3 w-full md:w-1/2 md:flex-col md:pr-2">
                <label htmlFor="role" className="mb-2 block dark:text-white">
                  Qyteti
                </label>
                <select
                  value={municipalityValue}
                  onChange={handleMunicipalityChange}
                  id="institucion"
                  name="institucion"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:text-white `}
                >
                  <option value="" disabled>
                    {" "}
                    Zgjedh qytetin{" "}
                  </option>
                  {municipalities.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className=" dark:text-white"
                    >
                      {" "}
                      {option.name}{" "}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3 w-full md:w-1/2 md:flex-col md:pr-2">
                <label
                  htmlFor="institucion"
                  className="mb-2 block dark:text-white"
                >
                  Lagjia
                </label>
                <select
                  value={neighborhoodValue}
                  onChange={handleNeighborhoodChange}
                  id="institucion"
                  name="institucion"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:text-white `}
                >
                  <option value="" disabled>
                    {" "}
                    Zgjedh lagjen{" "}
                  </option>
                  {neighborhood.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className=" dark:text-white"
                    >
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <p className="text-sm text-gray-600 dark:text-white">
                *Te dhënat e qytetit dhe lagjes duhet përshtatur varësisht nga
                vendi i punes
              </p>
            </div>

            <div className="flex flex-wrap pt-3">
              <div className="mb-3 w-full md:w-1/2 md:flex-col md:pr-2">
                <label
                  htmlFor="institucion"
                  className="mb-2 block dark:text-white"
                >
                  Institucioni
                </label>
                <select
                  value={institucionValue}
                  onChange={handleInstitutionsChange}
                  id="institucion"
                  name="institucion"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:text-white `}
                >
                  <option value="" disabled>
                    Zgjedh institucioni
                  </option>
                  {institucion.map((option) => (
                    <option
                      key={option.key}
                      value={option.value}
                      className=" dark:text-white"
                    >
                      {option.value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3 w-full md:w-1/2 md:flex-col md:pr-2">
                <label htmlFor="role" className="mb-2 block dark:text-white">
                  Pozita
                </label>
                <select
                  value={roleValue}
                  onChange={handleRoleChange}
                  id="institucion"
                  name="institucion"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:text-white `}
                >
                  <option value="" disabled>
                    Zgjedh poziten
                  </option>
                  {role.map((option, index) => (
                    <option
                      key={index}
                      value={option}
                      className="dark:text-white"
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex justify-end pe-2 pt-4">
              <button
                onClick={registerAdmin}
                className="linear sm: mb-4 mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:w-1/3"
              >
                Regjistro
              </button>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default Registerform;
