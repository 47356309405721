import React, { useMemo, useEffect, useState } from "react";
import Card from "components/card";
import axios from "../../../../api/axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import ReportDetailsModal from "./reportDetails";
import { TailSpin } from "react-loader-spinner";

const CheckTable = (props) => {
  const { columnsData, tableData } = props;
  const [showModal, setShowModal] = React.useState(false);
  const data = useMemo(() => tableData, [tableData]);
  const [selectedReport, setSelectedReport] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = useState(true);

  const fetchReportDetails = async (reportId) => {
    const storedToken = localStorage.getItem("token");
    const token = "Bearer " + storedToken.replace(/"/g, "");
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(`/api/reports/report/${reportId}`, {
        headers,
      });
      setSelectedReport(response.data);
      setShowModal(true);
    } catch (error) {}
  };
  const customLocaleText = {
    noRowsLabel: "Nuk ka raporte në proces",
    footerRowSelected: (count) =>
      `${count === 1 ? "1 rresht" : `${count} rreshta`} i selektuar`,
  };
  const columns = [
    {
      field: "name",
      headerName: <b>Emri i përdoruesit</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "date",
      headerName: <b>Data e raportimit</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
      valueFormatter: ({ value }) => {
        return new Date(value).toLocaleString("en-US", {
          dateStyle: "short",
          timeStyle: "short",
        });
      },
    },
    {
      field: "category",
      headerName: <b>Kategoria</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "senderEmail",
      headerName: <b>Email adresa</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "detajet",
      headerName: <b>Shiko detajet</b>,
      id: "mymodal",
      flex: 0.7,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <label
          id="mymodal"
          htmlFor="my-modal-5"
          className="rounded-xl bg-brand-500 px-[15px] py-[8px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={() => fetchReportDetails(params.row.id)}
        >
          Detajet
        </label>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token");

      let token = "Bearer " + storedToken.replace(/"/g, "");
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get("/api/reports/getallreports", {
          headers,
        });
        setRows(response.data.reverse());
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const gridLocalization = {
    props: {
      MuiGrid: {
        footerRowSelected: "rresht i selektuar",
      },
    },
  };

  return (
    <>
      {loading ? (
        <div className="mt-11 flex items-center justify-center pt-11">
          <TailSpin bg-brand-500 height={70} width={70} />
        </div>
      ) : (
        <Card extra={"w-full sm:overflow-auto p-4"}>
          <header className="relative flex items-center justify-between">
            <div className="pb-2 text-xl font-bold text-black text-navy-700 dark:text-white">
              Lista e raporteve të reja
            </div>
            {/* modali per raportin ne detaje */}
            <ReportDetailsModal selectedReport={selectedReport} />
          </header>
          <Box>
            <DataGrid
              rows={rows}
              columns={columns}
              className="text-black dark:text-white"
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableSelectionOnClick={true}
              disableColumnMenu
              localeText={customLocaleText}
            />
          </Box>
        </Card>
      )}
    </>
  );
};

export default CheckTable;
