import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "components/card";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import Swal from "sweetalert2";
import axios from "../../../../api/axios";
import { TailSpin } from "react-loader-spinner";

const CheckTable = (props) => {
  const [rows, setRows] = React.useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const storedToken = localStorage.getItem("token");
  const token = "Bearer " + storedToken.replace(/"/g, "");
  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };

  const handleEdit = (id) => {
    navigate(`/admin/userdetails`, { state: { id } });
  };

  const handleDelete = async (id) => {
    const response = await axios.delete(
      `/api/account/deleteAdministrator?userId=${id}`,
      { headers: headers }
    );

    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        text: "Administratori u fshi me sukses!",
        showConfirmButton: true,
        confirmButtonColor: "#18817d",
        confirmButtonText: "Mbyll",
        timer: 2500,
      });
      setTimeout(() => {
        window.location.href = "/admin/administrator";
      }, 2500);
    } else {
      Swal.fire({
        icon: "error",
        text: "Diçka shkoi keq! Ju lutem provoni përseri",
        showConfirmButton: true,
        confirmButtonColor: "#18817d",
        confirmButtonText: "Mbyll",
        timer: 2500,
      });
    }
  };
  const customLocaleText = {
    noRowsLabel: "Nuk ka administratorë të regjistruar",
    footerRowSelected: (count) =>
      `${count === 1 ? "1 rresht" : `${count} rreshta`} i selektuar`,
  };
  const columns = [
    {
      field: "fullName",
      headerName: <b>Emri dhe mbiemri</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "phone",
      headerName: <b>Nr. telefonit</b>,
      flex: 0.8,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "email",
      headerName: <b>Email adresa</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "role",
      headerName: <b>Pozita</b>,
      flex: 0.7,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "institution",
      headerName: <b>Institucioni</b>,
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerName: <b>Veprimet</b>,
      flex: 0.7,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div className="flex items-center space-x-2">
          <button onClick={() => handleEdit(rows[params.id]["userId"])}>
            <PencilAltIcon className="h-5 w-5 text-gray-500 hover:text-gray-700" />
          </button>
          <button onClick={() => handleDelete(rows[params.id]["userId"])}>
            <TrashIcon className="h-5 w-5 text-red-500 hover:text-red-700" />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token");

      let token = "Bearer " + storedToken.replace(/"/g, "");
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get("/api/account/getadministrators", {
          headers,
        });
        const rowsWithId = response.data.map((row, index) => ({
          ...row,
          id: index,
        }));
        setRows(rowsWithId);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="mt-11 flex items-center justify-center pt-11">
          <TailSpin bg-brand-500 height={70} width={70} />
        </div>
      ) : (
        <Card extra={"w-full sm:overflow-auto p-4"}>
          <header className="relative flex items-center justify-between">
            <div className="pb-2 text-xl font-bold text-navy-700 dark:text-white">
              Lista e administratorëve
            </div>
          </header>
          <Box>
            <DataGrid
              rows={rows}
              columns={columns}
              className="text-black dark:text-white"
              getRowId={(row) => row.id}
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableSelectionOnClick
              disableColumnMenu
              localeText={customLocaleText}
            />
          </Box>
        </Card>
      )}
    </>
  );
};

export default CheckTable;
