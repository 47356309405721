import { React, useState } from "react";
import axios from "../../../../api/axios";
import Swal from "sweetalert2";

const Refuse = (props) => {
  const [textValue, setTextValue] = useState("");

  const handleTextareaChange = (event) => {
    setTextValue(event.target.value);
  };

  const storedToken = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  let token = "Bearer " + storedToken.replace(/"/g, "");
  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };
  
  const body = {
    adminId: userId,
    userId: props.userVerify,
    refuseReason: textValue,
    verified: false,
  };
  const sendTextValue = () => {
      axios
      .post("/api/verification/verify", body, {
        headers: headers,
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Verifikimi është refuzuar me sukses!",
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
          timer: 2500,
        });
        setTimeout(() => {
          window.location.href = "/admin/verification";
        }, 2500);
      })

      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "Diçka shkoi keq! Ju lutem provoni përseri",
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
          timer: 2500,
        });
      });
  };
  return (
    <>
      <input type="checkbox" id="my-modal-6" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-5xl bg-white">
          <h3 className="flex justify-center text-lg font-bold">
            Refuzim i verifikimit
          </h3>
          <hr />
          <textarea
            placeholder="Shkruaj arsyjen e refuzimit"
            className="textarea-bordered textarea textarea-sm w-full bg-white"
            value={textValue}
            onChange={handleTextareaChange}
          ></textarea>

          <footer className="modal-action ">
            <label
              htmlFor="my-modal-6"
              className="btn-sm btn-circle btn absolute right-2 top-2"
            >
              ✕
            </label>
            <label
              htmlFor="my-modal-6"
              className="btn border-0 bg-brand-500 text-white hover:bg-brand-700"
            >
              Kthehu
            </label>
            <lable
              htmlFor="my-modal-6"
              className="btn border-0 bg-red-500 text-white hover:bg-red-600"
              onClick={sendTextValue}
            >
              Refuzo
            </lable>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Refuse;
