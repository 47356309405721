import axios from "../../api/axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Forgotpass() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    await axios.post(
      `/api/account/forgetpasswordtoken?email=${email}`,
      { "Content-Type": "application/json" }
    );
    navigate("/auth/check-email");

  };
  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[16vh] w-full max-w-full flex-col items-center lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-3xl font-bold text-navy-700 dark:text-white">
          Keni harruar fjalëkalimin?
        </h4>
        <p className="mb-8 ml-1 text-base text-gray-600">
          Shkruani email adresën me të cilën i'u është hapur llogaria në mRepo dhe vet sistemi do t'ua gjenerojë një fjalëkalim të përkohshëm!
        </p>
        <div className="mb-6 flex items-center gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        <input
          className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
          label="Email adresa*"
          placeholder="Shkruani email adresen"
          id="emailReset"
          type="email"
          value={email}
          onChange={ (e) => setEmail(e.target.value)}
        />
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center"></div>
        </div>
        <button
          onClick={handleSubmit}
          className="linear mt-2 w-full rounded-xl bg-brand-1000 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-1000 active:bg-brand-1000 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Dërgo
        </button>
      </div>
    </div>
  );
}
