import CheckTable from "./components/CheckTable";
import React  from 'react';
import { columnsDataCheck } from "./variables/columnsData";

const Administrator = () => {

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <CheckTable columnsData={columnsDataCheck}/>
      </div>
    </div>
  );
};

export default Administrator;
