import React, { useState, useEffect } from "react";
import Card from "components/card";
import axios from "../../../../api/axios";
import { useLocation, useNavigate } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner'

const Userdetails = () => {
  const user = {
    model: {
      id: "",
      name: "",
      email: "",
      phone: "",
      city: "",
      institution: "",
      role: "",
      pictureUrl: "",
    },
    picture: "",
  };
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const [institucion, setInstitucion] = useState([]);
  const [institucionValue, setInstitucionValue] = useState("");
  const [neighborhood, setNeighborhood] = useState([]);
  const [neighborhoodValue, setNeighborhoodValue] = useState("");
  const [administrator, setAdministrator] = useState(user);
  const [role, setRoles] = useState([]);
  const [roleValue, setRoleValue] = useState("");
  const [municipalities, setMunicipalities] = useState([]);
  const city = administrator.model.city;
  const [municipalityValue, setMunicipalityValue] = useState("");
  const [loading, setLoading] = useState(true);

  const storedToken = localStorage.getItem("token");
  let token = "Bearer " + storedToken.replace(/"/g, "");

  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };


  useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token");

      let token = "Bearer " + storedToken.replace(/"/g, "");
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get("/api/municipalities", { headers });
        const responseNeighborhood = await axios.get("/api/neighborhoods", {
          headers,
        });
        const responseInstitutions = await axios.get("/api/Institutions", {
          headers,
        });
        const responseRoles = await axios.get("/api/account/getroles", {
          headers,
        });

        const administratorResponse = await axios.get(
          `/api/account/getadministrator?userId=${id}`,
          { headers }
        );
        setAdministrator(administratorResponse.data);
        setMunicipalities(response.data);
        setNeighborhood(responseNeighborhood.data);
        setInstitucion(responseInstitutions.data);
        setRoles(responseRoles.data);
        setLoading(false);
      } catch (error) {
        setLoading(false); 
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async () => {
    const body = {
      UserId: id,
      Municipality: municipalityValue,
      OldRole: administrator.model.role,
      Role: roleValue,
      Institution: institucionValue,
      Neighborhood: neighborhoodValue,
    };
    const storedToken = localStorage.getItem("token");

    let token = "Bearer " + storedToken.replace(/"/g, "");
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      "/api/account/changeadministrator",
      JSON.stringify(body),
      { headers }
    );
    navigate("/admin/administrator");
  };

  const handleMunicipalityChange = (event) => {
    const selectedId = event.target.value;
    setMunicipalityValue(selectedId);
    fetchNeigborhoods(selectedId);
  };

  const fetchNeigborhoods = async (muniId) => {
    const response = await axios.get(`/api/neighborhoodbymuni?id=${muniId}`, {
      headers: headers,
    });
    setNeighborhood(response.data);
    setNeighborhoodValue("");
  };

  const handleNeighborhoodChange = (event) => {
    const selectedId = event.target.value;
    setNeighborhoodValue(selectedId);
  };

  return (
    <>
    {loading ? (
      <div className="flex items-center justify-center pt-11 mt-11">
        <TailSpin bg-brand-500 height={70} width={70} />
      </div>
    ) : (
    <Card extra={" w-full h-full p-[16px] bg-cover"}>
      <div className="relative flex h-32 w-full justify-center rounded-xl bg-cover">
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white dark:!border-navy-700">
          <img
            className="h-full w-full rounded-full"
            src={administrator.picture}
            alt=""
          />
        </div>
      </div>
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
          {administrator.model.name}
        </h4>
        <p className="text-base font-normal text-gray-600">
          {administrator.model.role}
        </p>
      </div>
      <div className="mb-3 mt-6 gap-4 md:!gap-14">
        <hr />
        <div className="flex flex-wrap">
          <div className="mb-2 mb-3 flex w-full flex-row items-center justify-center gap-2 md:w-1/2 md:flex-col md:pr-2">
            <p className="text-1xl font-normal text-gray-600">Email adresa:</p>
            <p className="text-1xl font-bold text-navy-700 dark:text-white">
              {administrator.model.email}
            </p>
          </div>
          <div className="mb-2 mb-3 flex w-full flex-row items-center justify-center gap-2 md:w-1/2 md:flex-col md:pr-2">
            <p className="text-1xl font-normal text-gray-600">Nr. telefonit:</p>
            <p className="text-1xl font-bold text-navy-700 dark:text-white">
              {administrator.model.phone}
            </p>
          </div>
        </div>
        <hr />
        <div className="flex flex-wrap">
          <div className="mb-2 mb-3 flex w-full flex-row items-center justify-center gap-2 md:w-1/2 md:flex-col md:pr-2">
            <p className="text-1xl font-normal text-gray-600">Qyteti:</p>
            <p className="text-1xl font-bold text-navy-700 dark:text-white">
              {administrator.model.city}
            </p>
          </div>
          <div className="mb-2 mb-3 flex w-full flex-row items-center justify-center gap-2 md:w-1/2 md:flex-col md:pr-2">
            <p className="text-1xl font-normal text-gray-600">Lagja:</p>
            <p className="text-1xl font-bold text-navy-700 dark:text-white">
              {administrator.model?.neighborhood}
            </p>
          </div>
        </div>
        <hr />
        <div className="flex flex-wrap">
          <div className="mb-2 mb-3 flex w-full flex-row items-center justify-center gap-2 md:w-1/2 md:flex-col md:pr-2">
            <p className="text-1xl font-normal text-gray-600">Pozita:</p>
            <p className="text-1xl font-bold text-navy-700 dark:text-white">
              {administrator.model.role}
            </p>
          </div>
          <div className="mb-2 mb-3 flex w-full flex-row items-center justify-center gap-2 md:w-1/2 md:flex-col md:pr-2">
            <p className="text-1xl font-normal text-gray-600">Institucioni:</p>
            <p className="text-1xl font-bold text-navy-700 dark:text-white">
              {administrator.model.institution}
            </p>
          </div>
        </div>
        <hr />
      </div>
      <h1 className="text-1xl flex justify-center text-center font-normal text-gray-600">
        Ndrysho të dhënat për zyrtarin
      </h1>
      <div className="flex flex-wrap">
          <div className="mb-3 w-full md:w-1/2 md:flex-col md:pr-2">
            <label
              htmlFor="role"
              className="mb-2 block  dark:text-white"
            >
              Qyteti
            </label>
            <select
              value={municipalityValue}
              onChange={handleMunicipalityChange}
              id="institucion"
              name="institucion"
              className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:text-white `}
            >
              <option value="" disabled>
                {" "}
                Zgjedh qytetin{" "}
              </option>
              {municipalities.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  className=" dark:text-white"
                >
                  {" "}
                  {option.name}{" "}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-3 w-full md:w-1/2 md:flex-col md:pr-2">
            <label
              htmlFor="institucion"
              className="mb-2 block dark:text-white"
            >
              Lagjia
            </label>
            <select
              value={neighborhoodValue}
              onChange={handleNeighborhoodChange}
              id="institucion"
              name="institucion"
              className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:text-white `}
            >
              <option value="" disabled>
                {" "}
                Zgjedh lagjen{" "}
              </option>
              {neighborhood.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  className=" dark:text-white"
                >
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <p className="text-sm text-gray-600 dark:text-white">
            *Te dhënat e qytetit dhe lagjes duhet përshtatur varësisht nga vendi
            i punes
          </p>
        </div>
      <div className="flex flex-wrap pt-3">
        <div className="mb-3 w-full md:w-1/2 md:flex-col md:pr-2">
          <label
            htmlFor="role"
            className="mb-2 block dark:text-white"
          >
            Pozita
          </label>
          <select
            value={roleValue}
            onChange={(e) => setRoleValue(e.target.value)}
            id="institucion"
            name="institucion"
            className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:text-white `}
          >
            <option value="" disabled>
              Zgjedh poziten
            </option>
            {role.map((option) => (
              <option
                key={option.key}
                value={option.key}
                className=" dark:text-white"
              >
                {option.value}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3 w-full md:w-1/2 md:flex-col md:pr-2">
          <label
            htmlFor="institucion"
            className="mb-2 block  dark:text-white"
          >
            Institucioni
          </label>
          <select
            value={institucionValue}
            onChange={(e) => setInstitucionValue(e.target.value)}
            id="institucion"
            name="institucion"
            className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:text-white `}
          >
            <option value="" disabled>
              Zgjedh institucioni
            </option>
            {institucion.map((option) => (
              <option
                key={option.key}
                value={option.key}
                className=" dark:text-white"
              >
                {option.value}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex-column flex justify-end">
        <button
          className="mb-4 mt-6 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:w-60"
          onClick={handleSubmit}
        >
          Ndrysho të dhënat e zyrtarit
        </button>
      </div>
      {/*<div className="flex flex-column justify-center">
        <button className="w-full md:w-60  mt-2 mb-4 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          <Link to="/admin/edit-pass">Ndrysho fjalëkalimin</Link>
        </button>
      </div> */}
    </Card>
    )}
    </>
  );
};

export default Userdetails;
