import React from "react";
import Refuse from "./Refuse";
import axios from "../../../../api/axios";
import Swal from "sweetalert2";

function VerificationDetails(props) {
  const { verificationModel, userId } = props;
  const date = new Date(verificationModel?.date);
  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  const storedToken = localStorage.getItem("token");
  let userVerify = verificationModel?.userId;
  let token = "Bearer " + storedToken.replace(/"/g, "");
  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };

  const sendTextValue = () => {
    const body = {
      adminId: "dec8113a-d62e-42b2-a891-2acbf1b41e41",
      userId: verificationModel.userId,
      verified: true,
    };
    axios
      .post("/api/verification/verify", body, {
        headers: headers,
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Verifikimi është aprovuar me sukses!",
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
          timer: 2500,
        });
        setTimeout(() => {
          window.location.href = "/admin/verification";
        }, 2500);
      })

      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "Diçka shkoi keq! Ju lutem provoni përseri",
          timer: 2500,
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
        });
      });
    //window.location.href = "/admin/verification"
  };
  return (
    <>
      <input type="checkbox" id="my-modal-5" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box bottom-0 w-11/12 max-w-5xl bg-white p-0 pe-6 ps-4">
          <div className="sticky top-0 bg-white pt-4">
            <h3 className="abdo sticky top-0 flex justify-center pb-2 text-lg font-bold text-brand-500">
              Verifikimi i perdoruesit!
            </h3>
            <label
              htmlFor="my-modal-5"
              className="btn-sm btn-circle btn absolute right-2 top-3 bg-white text-brand-700 hover:text-white"
            >
              ✕
            </label>
            <hr />
          </div>
          <div className="mb-4 flex items-center pt-4">
            <p className="text-black-500 text-lg font-bold dark:text-white">
              Emri i raportuesit:
            </p>
            <p className="ml-2 text-lg">{verificationModel?.name}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">Email:</p>
            <p className="ml-2 text-lg">{verificationModel?.email}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">Data:</p>
            <p className="ml-2 text-lg ">{formattedDate}</p>
          </div>
          <div className="mb-4 flex items-center">
            <p className="text-lg font-bold dark:text-white">
              Numri i telefonit:
            </p>
            <p className="ml-2 text-lg">{verificationModel?.phone}</p>
          </div>
          <div className="carousel w-full">
            {verificationModel?.documents.map((image, index) => (
              <div
                id={"slide" + index}
                className="carousel-item relative flex w-full justify-center pb-6"
              >
                <img src={image} class="h-80" alt="..." />
                <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
                  <a
                    href={
                      "#slide" +
                      (index === 0
                        ? verificationModel?.documents.length - 1
                        : index - 1)
                    }
                    className="btn-circle btn bg-white text-brand-700 hover:text-white"
                  >
                    ❮
                  </a>
                  <a
                    href={
                      "#slide" +
                      ((index + 1) % verificationModel?.documents.length)
                    }
                    className="btn-circle btn bg-white text-brand-700 hover:text-white"
                  >
                    ❯
                  </a>
                </div>
              </div>
            ))}
          </div>

          <footer className="modal-action sticky bottom-0 bg-white pb-3 pt-2">
            <label
              htmlFor="my-modal-5"
              className="btn border-0 bg-white text-brand-700 hover:text-white"
            >
              Kthehu
            </label>
            <label
              htmlFor="my-modal-6"
              className="btn border-0 bg-red-500 text-white hover:bg-red-600"
            >
              Refuzo
            </label>
            <Refuse userVerify={userVerify} />

            <label
              className="btn border-0 bg-brand-500 text-white hover:bg-brand-700"
              onClick={() => {
                sendTextValue();
              }}
            >
              Aprovo
            </label>
          </footer>
        </div>
      </div>
    </>
  );
}

export default VerificationDetails;
