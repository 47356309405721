/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";

export function SidebarLinks(props) {
  let location = useLocation();
  const { routes, onClick } = props;

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 1199) {
      onClick();
    }
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        if (route.subLinks) {
          return (
            <div key={index}>
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                  key={index}
                >
                  <span
                    className={`${
                      activeRoute(route.path) === true
                        ? "font-bold text-brand-500 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path) === true
                        ? "font-bold text-navy-700 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-1000 dark:bg-brand-1000" />
                ) : null}
              </div>
              {route.subLinks.map((subLink, subIndex) => (
                <Link
                  key={subIndex}
                  to={`${route.layout}/${route.path}/${subLink.path}`}
                  onClick={handleLinkClick}
                >
                  <div className="relative mb-3 flex hover:cursor-pointer">
                    <li
                      className="my-[3px] flex cursor-pointer items-center px-14"
                      key={subIndex}
                    >
                      <p
                        className={`leading-1 ml-4 flex ${
                          activeRoute(subLink.path) === true
                            ? "font-bold text-navy-700 dark:text-white"
                            : "font-medium text-gray-600"
                        }`}
                      >
                        {subLink.name}
                      </p>
                    </li>
                    {activeRoute(subLink.path) ? (
                      <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                    ) : null}
                  </div>
                </Link>
              ))}
            </div>
          );
        } else {
          return (
            <Link
              key={index}
              to={route.layout + "/" + route.path}
              onClick={handleLinkClick}
            >
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                  key={index}
                >
                  <span
                    className={`${
                      activeRoute(route.path) === true
                        ? "font-bold text-brand-500 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path) === true
                        ? "font-bold text-navy-700 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                ) : null}
              </div>
            </Link>
          );
        }
      }
    });
  };

  return createLinks(routes);
}

export default SidebarLinks;
