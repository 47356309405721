import React, { useState, useEffect } from "react";
import Card from "components/card";
import axios from "../../../../api/axios";
import { FaUpload } from "react-icons/fa";
import Swal from "sweetalert2";
import { TailSpin } from "react-loader-spinner";

const Changedata = () => {
  const [phone, setPhoneNumber] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);
  const storedToken = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  let token = "Bearer " + storedToken.replace(/"/g, "");
  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/account/getadministrator?userId=${userId}`,
          { headers }
        );
        setPhoneNumber(response.data.model.phone);
        setImage(response.data.picture);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("picture", image);
    formData.append("userId", userId);
    formData.append("phone", phone);

    axios
      .post("/api/account/changeProfile", formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Të dhënat tuaja u ndryshuan me sukses!",
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
          timer: 2500,
        });
        setTimeout(() => {
          window.location.href = "/admin/dashboard";
        }, 2500);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "Diçka shkoi keq! Ju lutem provoni përseri.",
          timer: 2500,
          showConfirmButton: true,
          confirmButtonColor: "#18817d",
          confirmButtonText: "Mbyll",
        });
      });
  };

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center pt-11 mt-11">
          <TailSpin bg-brand-500 height={70} width={70} />
        </div>
      ) : (
        <form>
          <Card extra={" w-full h-full p-[16px] bg-cover"}>
            <header className="items-center">
              <div className="pb-4 pt-4 text-center text-xl font-bold text-navy-700 dark:text-white">
                Ndrysho të dhënat personale
              </div>
              <hr />
            </header>
            <div className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover">
              <label className="image-input-label">
                <div className="relative h-[87px] w-[87px]">
                  <div className="absolute -bottom-12 flex h-full w-full items-center justify-center rounded-full border-[4px] border-white dark:!border-navy-700">
                    {image && (
                      <img
                        className="h-full w-full rounded-full"
                        alt="profile"
                        src={
                          image.toString() === "[object File]"
                            ? URL.createObjectURL(image)
                            : image
                        }
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      className="absolute inset-0 opacity-0"
                      style={{ width: "100%", height: "100%" }}
                      onChange={(event) => setImage(event.target.files[0])}
                    />
                  </div>
                  <div className="absolute -bottom-11 right-0 cursor-pointer rounded-full bg-white bg-opacity-25 p-2 hover:bg-opacity-100 dark:hover:bg-opacity-50">
                    <FaUpload />
                  </div>
                </div>
              </label>
            </div>
            <div className="mt-[8vh] flex flex-col items-center justify-center">
              <div className="mb-3 w-full lg:w-1/2">
                <label htmlFor="email">Ndrysho numrin</label>

                <input
                  placeholder="Nr. telefonit"
                  id="phonenumber"
                  type="number"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                  name="email"
                  value={phone}
                  onChange={(event) => setPhoneNumber(event.target.value)}
                />
              </div>
              <button
                onClick={handleSubmit}
                className="linear mb-4 mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 lg:w-1/2"
                type="submit"
              >
                Ndrysho
              </button>
            </div>
          </Card>
        </form>
      )}
    </>
  );
};

export default Changedata;
